import Ru from '../../../lang/site/ru.json';
import Ua from '../../../lang/site/ua.json';
import En from '../../../lang/site/en.json';
import Pl from '../../../lang/site/pl.json';
import Mx from '../../../lang/site/mx.json';

const l = () => {
    const langs = {
        ru: Ru,
        ua: Ua,
        en: En,
        pl: Pl,
        mx: Mx,
    };

    const activeLang = window.config.lang;
    const langActive = langs[activeLang];

    return (index, ...args) => {
        let str = langActive[index];

        if (typeof str === 'undefined' || str === null || str.toString().trim() === '') {
            str = index;
        }

        if (args.length > 0) {
            if (typeof args[0] === 'object') {
                for (const [key, val] of Object.entries(args[0])) {
                    str = str.replace(new RegExp(`%${key}`, 'g'), val);
                }
            } else {
                for (let i = 0; i < args.length; i += 1) {
                    str = str.replace('%s', args[i]);
                }
            }
        }

        return str;
    };
};

export default l();
