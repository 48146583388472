import React, { Component, createRef } from 'react';
import PosterUiKit from 'poster-ui-kit';
import IntlTelInput from 'react-intl-tel-input';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import PREFERRED_COUNTRIES_BY_LANG from '../../../consts/sitePreferredCountriesByLang';
import { CLIENT_STATUS_NEW } from '../../../crm/constants';
import { isMobile, getTermsAndPrivacyText } from '../../helpers/siteHelper';
import l from '../../helpers/siteLanguage';
import ContactWhatsAppBlock from './contactWhatsAppBlock';

export default class GatherContactForm extends Component {
    propTypes = {
        referral: PropTypes.string,
        cookieName: PropTypes.string,
        linkTo: PropTypes.string,
        comment: PropTypes.string,
        blank: PropTypes.bool,
        submitText: PropTypes.string,
    };

    defaultProps = {
        referral: false,
        cookieName: false,
        linkTo: false,
        comment: false,
        blank: false,
        submitText: false,
    };

    constructor(props) {
        super(props);

        this.nameInputRef = createRef();
        this.recaptchaRef = React.createRef();
    }

    state = {
        firstSubmitAttemptDone: false,
        formValid: false,
        inProgress: false,
        phoneUtilsLoaded: false,
        registerFinished: false,
        phoneRequestInProgress: false,
        formActive: false,
        dontHaveWhatsApp: false,

        name: { value: '', valid: false, showError: false },
        phone: {
            valid: false,
            value: '',
            fullNumber: '',
            country: window.config.country,
            showError: false,
        },

        formErrorMessage: '',
    }

    componentDidMount() {
        // Фокусим первый инпут в форме
        if (!isMobile()) {
            this.nameInputRef.current.focus();
            this.state.nameInputFocused = true;
        }
    }

    /**
     * Обработчик изменения инпута
     * @param inputName
     * @param e
     */
    onInputChange = (inputName, e) => {
        const { value } = e.target;
        let valid;

        switch (inputName) {
            case 'name': {
                valid = value.toString().length >= 2;
                break;
            }

            default:
                break;
        }

        this.setState({
            [inputName]: _.extend({}, this.state[inputName], {
                value,
                valid,
            }),
        }, () => {
            this.calculateFormValid();
        });
    }

    /**
     * Отдельный обработчик изменения номера телефона через intlPhoneNumber
     * @param valid
     * @param phone
     * @param country
     */
    onPhoneChange = (valid, phone, country) => {
        // посылаем запрос на бек для валидации телефона
        this.setState({
            phoneRequestInProgress: true,
            phone: _.extend({}, this.state.phone, {
                value: phone,
                country: country.iso2,
            }),
        });


        // формат выдаваемый фронтовой либой не всегда принимается на бекенде, оставляем старое преображение телефона
        if (window.intlTelInputUtils) {
            phone = window.intlTelInputUtils.formatNumber(
                '+'.concat(country.dialCode).concat(phone),
                country.iso2,
                window.intlTelInputUtils.numberFormat.E164,
            );
        }

        if (this.requestValidatePhone) {
            this.requestValidatePhone.abort();
        }

        this.requestValidatePhone = $.post(`${window.config.langUrl}/signup/validatePhone`, {
            phone,
        }, (answer) => {
            this.setState({
                phone: _.extend({}, this.state.phone, {
                    valid: answer.valid,
                    fullNumber: phone,
                }),
                phoneRequestInProgress: false,
            }, () => {
                this.calculateFormValid();
            });
        });
    };

    /**
     * Обработчик сабмита формы
     * @param e
     */
    onSubmit = async (e) => {
        e.preventDefault();

        const {
            formValid, name, phone, phoneRequestInProgress,
        } = this.state;

        const {
            referral, cookieName, linkTo, comment, blank,
        } = this.props;

        this.setState({
            firstSubmitAttemptDone: true,
        });

        if (formValid && !phoneRequestInProgress) {
            this.setState({
                inProgress: true,
            });

            if (blank) {
                // сначала открываем окно с демо акк, для того чтобы браузер не блочил всплывающие окна
                window.open(linkTo, '_blank');
            }
            // reCaptcha token
            const token = window.config.recaptchaKey ? await this.recaptchaRef.current.executeAsync() : '';

            this.registerRequest = $.post(`${window.config.langUrl}/gather-contact`, {
                company_name: name.value,
                phone: phone.fullNumber,
                referal: referral,
                country: window.config.country,
                lang: window.config.lang,
                status_type: CLIENT_STATUS_NEW,
                token,
                comment,
            }, (answer) => {
                if (!answer.error) {
                    setTimeout(() => {
                        this.setState({
                            inProgress: false,
                            registerFinished: true,
                        });

                        // пишем куку о том что пользователь уже дал контакт
                        Cookies.set(cookieName, 'true', { expires: 365, path: '/' });

                        // в зависимости от параметра blank переходим на конечный урл как нужно
                        if (!blank) {
                            window.location.href = linkTo;
                        } else {
                            // перезагружаем страницу т.к. кука записалась и нужно дать свободу юзеру
                            setTimeout(() => {
                                window.location.reload();
                            }, 100);
                        }
                    }, 1000);
                } else {
                    setTimeout(() => {
                        this.setState({
                            formErrorMessage: answer.error,
                        });
                    }, 1000);
                }
            });
        }
    };

    /**
     * Обработчки блура инпута
     * @param inputName
     */
    onInputBlur = (inputName) => {
        const inputState = this.state[inputName];

        // После того, как в инпут что-то ввели в первый раз и заблурили,
        // разрешаем показывать его ошибки валидации
        if (inputState.value.toString() !== '' || inputState.showError) {
            this.setState({
                [inputName]: _.extend({}, inputState, {
                    showError: true,
                }),
            });
        }
    }

    /**
     * Обработчки фокуса инпута
     * @param inputName
     */
    onInputFocus = (inputName) => {
        if (!this.state.formActive) {
            this.setState({
                formActive: true,
            });
        }
    }

    /**
     * Считаем, сколько цифр должно быть в номере телефона для текущей выбранной страны
     * @returns {boolean|number}
     */
    phoneNumberLength = () => {
        if (window.intlTelInputUtils) {
            return window.intlTelInputUtils.getExampleNumber(
                this.state.phone.country,
                false,
                window.intlTelInputUtils.numberType.MOBILE,
            ).replace(/[^0-9]/g, '').length;
        }

        return false;
    }

    /**
     * Возвращаем текст ошибки валидации
     * @param field
     */
    validationError = (field) => {
        const camelCaseField = field.charAt(0).toUpperCase() + field.slice(1);
        const emptyLangKey = `site.signupForm.validationError${camelCaseField}Empty`;
        const incorrectLangKey = `site.signupForm.validationError${camelCaseField}Incorrect`;

        if (this.state[field].value.toString().length === 0) {
            return l(emptyLangKey);
        }

        // Если есть расширенная валидация у этого поля, возвращаем ее
        if (l(incorrectLangKey) !== incorrectLangKey) {
            return l(incorrectLangKey);
        }

        return l(emptyLangKey);
    }

    /**
     * Возвращаем текст ошибки валидации телефона
     */
    phoneValidationError = () => {
        const phoneNumberLength = this.phoneNumberLength();
        const { phone } = this.state;

        if (phone.value.toString().length === 0) {
            return l('site.signupForm.validationErrorPhoneEmpty');
        }

        // Если знаем, сколько цифр должно быть в номере телефона этой страны
        if (phoneNumberLength !== false && phone.fullNumber.replace(/[^0-9]/g, '').length !== phoneNumberLength) {
            return l('site.signupForm.validationErrorPhoneIncorrectDigitsCount', { number: phoneNumberLength });
        }

        return l('site.signupForm.validationErrorPhoneIncorrect');
    }

    /**
     * Определяем, валидная ли форма
     */
    calculateFormValid = () => {
        const { name, phone } = this.state;

        this.setState({
            formValid: name.valid && phone.valid,
        });
    };

    /**
     * Обработчик кнопки "У меня нет Whatsapp"
     */
    dontHaveWhatsApp = () => {
        this.setState({
            dontHaveWhatsApp: true,
        });
    }

    render() {
        let preferredCountries = PREFERRED_COUNTRIES_BY_LANG[window.config.lang] || [];
        preferredCountries = _.without(preferredCountries, window.config.country);
        preferredCountries.unshift(window.config.country);

        const {
            firstSubmitAttemptDone,
            name,
            phone,
            urlRequestInProgress,
            formValid,
            inProgress,
            registerFinished,
            phoneRequestInProgress,
            formErrorMessage,
            formActive,
            dontHaveWhatsApp,
        } = this.state;

        const { submitText } = this.props;
        // Форма сбора контактов в MX версии сайта для мобла поменяна на форму с кнопкой, при нажатии которой клиент
        // переходит в WhatsApp с предварительно написаным сообщением.
        // При этом доступа к демо нет, в CRM контакт не создается
        const showWhatsAppContactMessage = window.config.lang === 'mx' && isMobile() && !dontHaveWhatsApp && !registerFinished;

        return (
            <div>
                <div className="form-header p-6 pb-0 text-center">
                    <p className="title mt-4 fw-bold mb-4">{l('site.gatherContactForm.title')}</p>
                </div>


                { showWhatsAppContactMessage ? (
                    <ContactWhatsAppBlock
                        dontHaveWhatsApp={this.dontHaveWhatsApp}
                    />
                ) : !registerFinished && (
                    <form
                        action=""
                        onSubmit={this.onSubmit}
                        onClick={this.handleFormFocus}
                        className="gather-contact-form px-6 text-center"
                    >
                        { window.config.recaptchaKey && formActive && (
                            <ReCAPTCHA
                                ref={this.recaptchaRef}
                                size="invisible"
                                sitekey={window.config.recaptchaKey}
                            />
                        )}

                        <p
                            className="description mb-5 text-black-50"
                            dangerouslySetInnerHTML={{ __html: l('site.gatherContactForm.description') }}
                        />

                        <PosterUiKit.FormGroup
                            label={l('site.signupForm.yourName')}
                            id="gather-input-name"
                            error={(firstSubmitAttemptDone || name.showError) && !name.valid ? this.validationError('name') : ''}
                            vertical
                            className={classNames([{ 'minimized-label': (name.value.toString() !== '') }])}
                        >
                            <input
                                type="text"
                                id="gather-input-name"
                                value={name.value}
                                onInput={this.onInputChange.bind(this, 'name')}
                                onBlur={this.onInputBlur.bind(this, 'name')}
                                onFocus={this.onInputFocus.bind(this, 'name')}
                                disabled={inProgress}
                                ref={this.nameInputRef}
                                autoComplete="off"
                                placeholder={l('site.signupForm.yourName')}
                            />

                            { name.valid && (
                                <div className="valid-checkmark" />
                            )}
                        </PosterUiKit.FormGroup>

                        <PosterUiKit.FormGroup
                            id="gather-input-phone"
                            vertical
                            error={(firstSubmitAttemptDone || phone.showError) && !phone.valid ? this.phoneValidationError() : ''}
                            className={classNames([{ 'minimized-label': phone.value.toString() !== '' }])}
                        >
                            <IntlTelInput
                                containerClassName={classNames(['intl-tel-input', {
                                    'input-error': firstSubmitAttemptDone && !phone.valid,
                                }])}
                                preferredCountries={preferredCountries}
                                format
                                separateDialCode
                                autoPlaceholder
                                fieldId="gather-input-phone"
                                className="ps-6"
                                onPhoneNumberChange={this.onPhoneChange}
                                onPhoneNumberBlur={this.onInputBlur.bind(this, 'phone')}
                                value={phone.value}
                                disabled={inProgress}
                            />

                            { phoneRequestInProgress && (
                                <PosterUiKit.Spinner />
                            )}

                            { !phoneRequestInProgress && phone.valid && (
                                <div className="valid-checkmark" />
                            )}
                        </PosterUiKit.FormGroup>

                        { formErrorMessage && (
                            <div
                                className="error-message fs-6 mb-5 text-danger"
                                dangerouslySetInnerHTML={{ __html: formErrorMessage }}
                            />
                        )}

                        <PosterUiKit.Button
                            className="btn shadow-none rounded-3 py-2 px-4 height-auto
                                btn-primary h-auto"
                            inputBlocked={
                                (firstSubmitAttemptDone && !formValid) ||
                                urlRequestInProgress ||
                                inProgress}
                            inProgress={inProgress}
                        >
                            {submitText}
                        </PosterUiKit.Button>
                    </form>
                )}

                <div>
                    <p
                        className="mt-4 p-6 pt-0 text-center fs-6 lh-base text-black-50"
                        dangerouslySetInnerHTML={{ __html: getTermsAndPrivacyText(submitText) }}
                    />
                </div>
            </div>
        );
    }
}
