import { Component } from 'react';
import { testimonials } from './testimonials.json';

export default class TestimonialBlock extends Component {
    componentDidMount() {
    }

    render() {
        let testimonialsArray = testimonials[window.config.lang];

        if (window.config.lang === 'ru' && window.config.country === 'UA') {
            testimonialsArray = testimonials['ua-ru'];
        }

        const testimonialItem = testimonialsArray[Math.floor(Math.random() * testimonialsArray.length)];

        return (
            <div className="testimonial-wrapper">
                { testimonialItem && (
                    <div className="testimonial">
                        <div className="testimonial-text" dangerouslySetInnerHTML={{ __html: testimonialItem.text }}></div>

                        <div className="author-block row">
                            <div className="col-xs-12 col-sm-3 picture">
                                <img src={testimonialItem.picture} alt={testimonialItem.authorName} />
                            </div>
                            <div className="col-xs-12 col-sm-9">
                                <div className="author-name">{testimonialItem.authorName}</div>
                                <div className="author-about" dangerouslySetInnerHTML={{ __html: testimonialItem.authorAbout }}></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
