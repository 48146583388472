function Validate($form) {
	var $inputs = $form.find('.validate'),
		$button = $form.find('.primary'),
		firstValidation = false;

	function doValidate() {
		var ok = true;

		function error($input, text) {
			ok = false;

			$input.addClass('input-error');

            if ($input.prop('class').indexOf('policy_checkbox') > -1) {
                if (text && text !== '') {
                    $input.next('.without-styles').next('.error').remove();
                    $input.next('.without-styles').after('<div class="error error-padding0">' + text + '</div>');
                }
            } else {
                if (text && text !== '') {
                    $input.next('.error').remove();
                    $input.after('<div class="error">' + text + '</div>');
                }
            }
		}

		function validateEmail (email) {
			email = $.trim(email);

			var emailRegexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (emailRegexp.test(email)) {
				return email;
			} else {
				return false;
			}
		}

		$inputs.each(function () {
			if (parseInt($(this).data('required'))) {
				var id = $(this).prop('id'),
					type = $(this).attr('type'),
					val = $.trim($(this).val()),
                    classes = $(this).prop('class'),
					regexp;

				$(this)
					.removeClass('input-error')
					.next('.error').remove();

                if (classes.indexOf('policy_checkbox') > -1) {
                    $(this).next('.without-styles').next('.error').remove();
                }

                if ($(this).data('type') === 'privacy_policy' && !$(this).is(':checked')) {
                    error($(this), $(this).data('empty'));

                } else if (val == '' || (type === "checkbox" && !$(this).is(":checked"))) {
					error($(this), $(this).data('empty'));

				} else if ($(this).data('pattern')) {
					regexp = new RegExp($(this).data('pattern'));

					if (! regexp.test(val)) {
						error($(this), $(this).data('wrong'));
					}

				} else if ($(this).data('type') == 'email') {
					if (validateEmail(val) == false) {
						error($(this), $(this).data('wrong'));
					}

				} else if ($(this).data('equals')) {
					if ($('#' + $(this).data('equals')).val() !== val) {
						error($(this), $(this).data('wrong'));
					}

				} else if ($(this).data('type') == 'intl-phone') {
					if ($(this).hasClass('intl-invalid')) {
						error($(this), $(this).data('wrong'));
					}

				}
			}
		});
		
		if ( ! ok) {
			$button.addClass('disabled').attr('disabled', 'disabled');
		} else {
			$button.removeClass('disabled').removeAttr('disabled');
		}

		return ok;
	}

	$form.bind('submit', function(e) {
		firstValidation = true;

		if( ! doValidate()) {
			e.preventDefault();
			e.stopPropagation();
		}
	});

	$inputs.bind('keyup change', function() {
		if (firstValidation) {
			doValidate();
		}
	});

	this.triggerValidation = function () {
        if (firstValidation) {
            doValidate();
        }
	}
}

export default Validate;