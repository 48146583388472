import recaptchaV3Service from './recaptchaV3Service';

const httpRequest = async ({
    method,
    path,
    params = {},
    recaptchaToken = false,
}) => {
    if (recaptchaToken) {
        params.token = recaptchaToken;
    }
    const formData = new FormData();

    for (const name in params) {
        formData.append(name, params[name]);
    }

    const response = await fetch(path, {
        method,
        // headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // 'Content-Type': 'multipart/form-data',
        // },
        // body: new URLSearchParams(params),
        body: formData,
    });

    return response.json();
};

const getUrl = path => `${window.config.langUrl}/${path}`;

const sendDomainReminderEmail = async email => httpRequest({
    method: 'POST',
    path: getUrl('accountRecoveryAccess/sendDomainReminderEmail'),
    params: { email },
    recaptchaToken: await recaptchaV3Service('sendDomainReminderEmail'),
});

export default {
    sendDomainReminderEmail,
};
