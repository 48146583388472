import { Component } from 'react';
import PropTypes from 'prop-types';

export default class ContactWhatsAppBlock extends Component {
    propTypes = {
        dontHaveWhatsApp: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { dontHaveWhatsApp } = this.props;

        return (
            <div
                className="gather-contact-form px-6 text-center py-2"
            >
                <a
                    className="btn shadow-none rounded-3 py-2 px-4 height-auto
                        btn-primary h-auto w-100"
                    href="https://api.whatsapp.com/send/?phone=%2B525585266685&text=Quiero+hablar+con+un+vendor.&app_absent=0"
                >
                    Hablar con un vendedor en WhatsApp
                </a>

                <a
                    className="text-muted d-block text-decoration-none mt-4"
                    onClick={() => dontHaveWhatsApp()}
                >
                    No tengo WhatsApp
                </a>
            </div>
        );
    }
}
