import React from 'react';
import PropTypes from 'prop-types';
import l from '../../helpers/siteLanguage';
import {
    RESPONSE_SUCCESS,
    RESPONSE_INVALID_EMAIL,
    RESPONSE_ERROR,
    SIGN_IN_STEP_RECOVERY_FORM,
    SIGN_IN_STEP_RECOVERY_FORM_RESULT,
} from '../../../consts/site/signin';
import apiAgent from '../../services/apiService';
import { isValidEmail } from '../../helpers/siteHelper';

class RemindUrlForm extends React.Component {
    static propTypes = {
        onBackToSignInClick: PropTypes.func.isRequired,
    }

    static responseContentMapping = {
        [RESPONSE_SUCCESS]: {
            title: l('site.footer.remindUrlFormResponseTitleMailSent'),
            body: l('site.footer.remindUrlFormResponseTextMailSent'),
            button: l('site.footer.remindUrlFormBackToPreviousStep'),
        },
        [RESPONSE_INVALID_EMAIL]: {
            title: l('site.footer.remindUrlFormResponseTitleWrongEmail'),
            body: l('site.footer.remindUrlFormResponseErrorTextWrongEmail'),
            button: l('site.footer.remindUrlFormResponseButtonTryAgain'),
        },
        [RESPONSE_ERROR]: {
            title: l('site.footer.remindUrlFormResponseTitleSomethingWrong'),
            body: l('site.footer.remindUrlFormResponseErrorTextSomethingWrong'),
            button: l('site.footer.remindUrlFormResponseButtonTryAgain'),
        },
    }

    state = {
        step: SIGN_IN_STEP_RECOVERY_FORM,
        responseCode: null,
        isSubmitButtonDisabled: false,
        email: '',
        emailValidationError: false,
    }

    onBackToRecoveryFormClick = () => {
        this.setState({
            step: SIGN_IN_STEP_RECOVERY_FORM,
            email: '',
            responseCode: null,
        });
    }

    onSupportLinkClick = (e) => {
        e.preventDefault();
        if (window.Intercom) {
            window.Intercom('showNewMessage');
        }
    }

    onRemindFormResultButtonClick = () => {
        if (this.state.responseCode === RESPONSE_SUCCESS) {
            this.props.onBackToSignInClick();
        } else {
            this.onBackToRecoveryFormClick();
        }
    }

    handleSubmit = async () => {
        const { email } = this.state;
        const isEmailValid = isValidEmail(email);
        this.setState({ emailValidationError: !isEmailValid });

        if (isEmailValid) {
            this.setState({ isSubmitButtonDisabled: true });
            const response = await apiAgent.sendDomainReminderEmail(this.state.email);

            this.setState({
                step: SIGN_IN_STEP_RECOVERY_FORM_RESULT,
                responseCode: RemindUrlForm.responseContentMapping[response.response_code]
                    ? response.response_code
                    : RESPONSE_ERROR,
                isSubmitButtonDisabled: false,
            });
        }
    }
    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    };

    render() {
        const {
            step,
            responseCode,
            isSubmitButtonDisabled,
            emailValidationError,
        } = this.state;
        const { onBackToSignInClick } = this.props;

        return (
            <div>
                { step === SIGN_IN_STEP_RECOVERY_FORM && (
                    <div>
                        <h2 className="fs-2">{l('site.footer.remindUrlFormTitle')}</h2>

                        <p className="text-muted my-4"><small>{l('site.footer.remindUrlFormHintEnterEmail')}</small></p>

                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">{l('site.footer.remindUrlFormEmail')}</label>
                            <input
                                data-e2e-selector="input-remind-url-email"
                                type="email"
                                name="email"
                                id="email"
                                className="form-control shadow-none rounded-3"
                                placeholder="email@domain.com"
                                data-empty={l('site.footer.remindUrlFormErrorEmptyEmail')}
                                required="true"
                                onChange={this.handleChangeEmail}
                            />
                            { emailValidationError && (
                                <div
                                    data-e2e-selector="text-remind-url-error"
                                    className="error"
                                >
                                    {l('site.footer.remindUrlFormErrorEmptyEmail')}
                                </div>
                            )}
                        </div>

                        <button
                            data-e2e-selector="button-remind-url-submit"
                            className="p-btn btn shadow-none rounded-3 py-2 px-4 height-auto btn-primary h-auto mt-2"
                            onClick={this.handleSubmit}
                            disabled={isSubmitButtonDisabled}
                        >
                            {l('site.footer.remindUrlFormSendButton')}
                        </button>
                        <br />
                        <a
                            className="btn btn-link text-decoration-none shadow-none p-0 fs-6 mt-6"
                            onClick={onBackToSignInClick}
                        >
                            {l('site.footer.remindUrlFormBackToPreviousStep')}
                        </a>
                    </div>
                )}

                { step === SIGN_IN_STEP_RECOVERY_FORM_RESULT && (
                    <div>
                        <h2
                            data-e2e-selector="text-response-title"
                            className="fs-2"
                        >
                            {RemindUrlForm.responseContentMapping[responseCode]?.title}
                        </h2>
                        <p
                            data-e2e-selector="text-remind-url-response"
                            className="mt-6"
                        >
                            {RemindUrlForm.responseContentMapping[responseCode]?.body}
                        </p>
                        <button
                            data-e2e-selector="button-get-back-to-login"
                            className="p-btn btn shadow-none rounded-3 py-2 px-4 height-auto btn-primary h-auto"
                            onClick={this.onRemindFormResultButtonClick}
                        >
                            {RemindUrlForm.responseContentMapping[responseCode]?.button}
                        </button>

                        <p className="p-0 fs-6 mt-7">
                            {l('site.footer.remindUrlFormResponseSupportLinkLabel')}&nbsp;
                            <a
                                className="text-decoration-none"
                                href="#"
                                onClick={this.onSupportLinkClick}
                            >
                                {l('site.footer.remindUrlFormResponseSupportLink')}
                            </a>
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

export default RemindUrlForm;
