export function loadScript(src, callback) {
    const script = document.createElement('script');
    script.onload = callback;
    script.src = src;
    document.head.appendChild(script);
}

export function loadStyles(src, callback) {
    const styles = document.createElement('link');

    styles.rel = 'stylesheet';
    styles.href = src;
    styles.onload = callback;
    document.head.appendChild(styles);
}

export function getCookie(name) {
    const cookie = ` ${document.cookie}`;
    const search = ` ${name}=`;
    let setStr = null;
    let offset = 0;
    let end = 0;
    if (cookie.length > 0) {
        offset = cookie.indexOf(search);
        if (offset !== -1) {
            offset += search.length;
            end = cookie.indexOf(';', offset);
            if (end === -1) {
                end = cookie.length;
            }
            setStr = unescape(cookie.substring(offset, end));
        }
    }
    return (setStr);
}

export function setCookie(name, value, expires, path, domain, secure) {
    const nameString = `${name}=${escape(value)}`;
    const expiresString = (expires) ? `; expires=${expires}` : '; expires=Mon, 07-Dec-2030 00:00:00 GMT';
    const pathString = (path) ? `; path=${path}` : '; path=/';
    const domainString = (domain) ? `; domain=${domain}` : '';
    const secureString = (secure) ? '; secure' : '';
    document.cookie = `${nameString}${expiresString}${pathString}${domainString}${secureString}`;
}

/**
 * @param {string} login
 * @param {string} password
 */
export function setStageAccount(login, password) {
    setCookie('stage_account', login, null, null, '.joinposter.com');
    setCookie('stage_pass', password, null, null, '.joinposter.com');
}

/**
 * @returns {{password: string, login: string}}
 */
export function getStageAccount() {
    return {
        login: getCookie('stage_account') || '',
        password: getCookie('stage_pass') || '',
    };
}

export function removeStageLogin() {
    setCookie('stage_account', '', new Date(0).toUTCString(), null, '.joinposter.com');
}

export function removeStagePassword() {
    setCookie('stage_pass', '', new Date(0).toUTCString(), null, '.joinposter.com');
}
