import { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import PosterUiKit from 'poster-ui-kit';
import classNames from 'classnames';

/**
 * Форма StepAccount может использоваться как inline-форма на странице,
 * так и как первый шаг полноэкранной формы регистрации. StepAccountInput —
 * обертка, которая в зависимости от того, в каком контексте используется форма
 * StepAccount, рендерит FormGroup с лейблом или просто инпут напрямую
 */
export default class StepAccountInput extends Component {
    propTypes = {
        inlineForm: PropTypes.bool.isRequired,
    };

    render() {
        const {
            inlineForm, children, label, error, className,
        } = this.props;

        if (inlineForm) {
            const alteredChildren = Children.map(children, (child) => {
                const alterProps = child.type === 'input' ? {
                    // В инлайновой версии формы заменяем placeholder инпута
                    // на текст лейбла, который используется в полной версии
                    placeholder: label,

                    // Прокидываем classname input-error на уровень инпута, если у группы есть ошибка
                    className: classNames(child.props.className, { 'input-error': error }),

                    // Очищаем ID, т.к. она нам не нужен для лейбла,
                    // а иначе на странице может оказаться несколько элементов с одинаковым ID
                    id: '',
                } : {};
                return cloneElement(child, alterProps);
            });

            return (
                <div className={classNames(['ib', className])}>
                    {alteredChildren}
                </div>
            );
        }

        return (
            <PosterUiKit.FormGroup {...this.props}>
                {children}
            </PosterUiKit.FormGroup>
        );
    }
}
