import { load } from 'recaptcha-v3';
import { getCookie } from '../pos/login/helper';
import intlTelInput from 'intl-tel-input';
import { sendAnalytics } from '../manage/helpers/helperGA';
import Validate from './validate.js';
import sbjs from 'sourcebuster';
import 'magnific-popup';
import './jquery.touchslider';
import SignupForm from './components/signup/signupForm';
import StepAccount from './components/signup/stepAccount';
import TestimonialBlock from './components/blocks/testimonialBlock';
import GatherContactForm from './components/blocks/gatherContactForm';
import {
    isMobile,
    changeAllWidgetsVisiblity,
    virtualPageView,
    isSignupUrl,
    changeBinotelWidgetVisiblity,
    isCountryFromCIS
} from './helpers/siteHelper';
import { USER_ID_POZZHE_FEDOR } from '../crm/constants';
import l from "./helpers/siteLanguage";
import SigninForm from './components/signin/signinForm';

function initPhoneInput($phoneInput, validation) {
    const intl = intlTelInput($phoneInput[0], {
        utilsScript: '/js/site/libs/intl-tel-input-utils-15-1-0.js',
        initialCountry: $('body').data('country'),
        separateDialCode: true,
        formatOnDisplay: true,
    });

    $phoneInput.on('keyup change', _.debounce(() => {
        if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
            const currentText = intl.getNumber(intlTelInputUtils.numberFormat.E164);
            if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                intl.setNumber(currentText); // will autoformat because of formatOnDisplay=true
            }

            if (intl.isValidNumber()) {
                $phoneInput.removeClass('intl-invalid');
            } else {
                $phoneInput.addClass('intl-invalid');
            }

            validation.triggerValidation();
        }

        const $hiddenInput = $phoneInput.parents('.intl-tel-input').siblings('input[name="phone"]');
        $hiddenInput.val(`+${intl.getSelectedCountryData().dialCode}${$phoneInput.val().replace(/[^0-9]/g,'')}`);
    }, 100));

    return intl;
}

/**
 * запрос на наш сервер вместе с сервисом reCaptcha
 *
 * если в конфиге есть ключ для капчи - делаем запрос с проверкой, если ключа нет хардкодим ключ
 * @param actionName
 * @param params
 * @param callback
 * @param skipRecaptcha если прокинули true - не используем капчу
 */
function recaptcha3VRequest(actionName, params, callback, skipRecaptcha) {
    skipRecaptcha = skipRecaptcha || null;
    if (skipRecaptcha) {
        params.token = true; // Hotfix
        callback(params);
    } else {
        load((window.config.recaptchaKey) || '6LeFMbcZAAAAAHV0ObT1obNWGeTLe-5tGUO3q0Br', {
            useRecaptchaNet: true,
            autoHideBadge: true,
        }).then((recaptcha) => {
            recaptcha.execute(actionName)
                .then((token) => {
                    params.token = token;
                    callback(params);
                })
                .catch((error) => {
                    callback(params);
                    console.log(error);
                });
        });
    }
}

$(function () {
    function toTranslit(text) {
        return text.replace(/([а-яёіїєґ])|([\s_-])|([^a-z\d])/gi,
            function (all, ch, space, words, i) {
                if (space || words) {
                    return space ? '-' : '';
                }
                var result,
                    code = ch.charCodeAt(0),
                    index = code == 1025 || code == 1105 ? 0 :
                        code > 1071 ? code - 1071 : code - 1039,
                    translitGeneral = ['yo', 'a', 'b', 'v', 'g', 'd', 'e', 'zh',
                        'z', 'i', 'y', 'k', 'l', 'm', 'n', 'o', 'p',
                        'r', 's', 't', 'u', 'f', 'h', 'c', 'ch', 'sh',
                        'shch', '', 'y', '', 'e', 'yu', 'ya',
                    ];

                // іїєґІЇЄҐ
                if ([1110, 1111, 1108, 1169, 1030, 1031, 1028, 1168].includes(code)) {
                    const translitUkr = {
                        1030: 'i',
                        1110: 'i',
                        1031: 'i',
                        1111: 'i',
                        1028: 'ie',
                        1108: 'ie',
                        1168: 'g',
                        1169: 'g',
                    };

                    result = translitUkr[code];
                } else {
                    result = translitGeneral[index];
                }

                return result;
            });
    }

    function setCookie(name, value, expires, path, domain, secure) {
        document.cookie = name + "=" + escape(value) + ((expires) ? "; expires=" + expires : "; expires=Mon, 07-Dec-2030 00:00:00 GMT") + ((path) ? "; path=" + path : "; path=/") + ((domain) ? "; domain=" + domain : "") + ((secure) ? "; secure" : "");
    }

    /*
        Images LazyLoad
     */
    function lazyLoad() {
        const drawPoint = $(window).height() + $(window).scrollTop() + $(window).height();

        $('.lazy, img[data-src], img[data-srcset]').each(function () {
            if ($(this).offset().top < drawPoint) {
                /* показываем lazy loaded изображение если есть кука */
                let errorImages = [];
                if (window.config.lazyLoad) {
                    $(this).addClass('lazyProcessed');

                    /* проверяем на неправильное заполнение атрибутов для lazyload */
                    if (($(this).attr('data-srcset') || ($(this).attr('srcset'))) && !$(this).attr('data-src')) {
                        errorImages.push($(this));
                    } else if ((!$(this).attr('data-srcset') && $(this).attr('srcset')) && $(this).attr('data-src')) {
                        errorImages.push($(this));
                    }
                }

                if ($(this).attr('data-src')) {
                    $(this).attr('src', $(this).attr('data-src'));
                    $(this).removeAttr('data-src');
                }

                if ($(this).attr('data-srcset')) {
                    $(this).attr('srcset', $(this).attr('data-srcset'));
                    $(this).removeAttr('data-srcset');
                }

                $(this).removeClass('lazy');

                if (window.config.lazyLoad) {
                    if (errorImages.length) {
                        errorImages.forEach(function (item) {
                            console.log('%c Error in lazyload attributes!', 'color: red; font-size: 20px;');
                            console.log(item);
                        });
                    }
                }
            }
        });
    }

    lazyLoad();

    window.addEventListener('scroll', _.throttle(lazyLoad, 100));
    window.addEventListener('resize', _.throttle(lazyLoad, 100));

    /*
        Верхняя менюшка
    */
    const header = $('#header');
    const headerMainMenu = $('#header-nav__menu-main');
    const navDropdownItems = $('.nav__item-with-dropdown .dropdown');
    const menuBurger = $('#menu-burger');
    const headerShadow = $('.mobile-header-shadow');
    const documentBody = $('body');
    const headerSignUpButton = $('#header .signup.open-fullscreen');

    const closeBurgerMenu = () => {
        documentBody.css('overflow', '');
        documentBody.css('touch-action', '');

        headerMainMenu.removeClass('active');
        menuBurger.removeClass('active');
        headerShadow.removeClass('active');
        navDropdownItems.removeClass('active');
    };

    if (window.innerWidth <= 900) {
        $('#header-nav__menu-main .show-signin-form').bind('click', function (e) {
            closeBurgerMenu();
        });

        $(document).on('swiperight', '#header-nav__menu-main', function(e){
            closeBurgerMenu();
        });

        menuBurger.on('click', () => {
            if (headerMainMenu.hasClass('active')) {
                closeBurgerMenu();
                return;
            }

            changeAllWidgetsVisiblity(false);

            documentBody.css('overflow', 'hidden');
            documentBody.css('touch-action', 'none');

            headerMainMenu.addClass('active');
            menuBurger.addClass('active');
            headerShadow.addClass('active');
        });

        /**
         * Ивент открытия подменю на мобильной версии сайта
         */
        $('.nav__item-with-dropdown').bind('click', function () {
            $(this).find('.dropdown').addClass('active');
        });

        /**
         * Ивент закрытия подменю на мобильной версии сайта
         */
        $('#header .dropdown-back-button').bind('click', function (e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            $(this).closest('.dropdown').removeClass('active');
        });

        /**
         * Закрываем мобильное меню по нажатию на тень
         */
        $('#header .mobile-header-shadow').bind('click', function () {
            closeBurgerMenu();
        });
    }

    /*
        Для кнопки signup при скролле вешаем класс primary
     */
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 0) {
            if (headerSignUpButton.hasClass('btn-outline-primary')) {
                headerSignUpButton.removeClass('btn-outline-primary').addClass('btn-primary');
            }
        } else {
            if (headerSignUpButton.hasClass('btn-primary')) {
                headerSignUpButton.removeClass('btn-primary').addClass('btn-outline-primary');
            }
        }
    });

    $('.open-call-me-modal').on('click', () => {
        const modal = new bootstrap.Modal('#call-me-modal');

        // если мобильное -- скрываем меню
        if (window.innerWidth <= 900) {
            closeBurgerMenu();
        }

        // если есть бинотель -- показываем его вместо нашего модаля
        if ($('#bingc-phone-button-icon-icon').length) {
            $('#bingc-phone-button-icon-text').click();
        } else {
            modal.show();
        }
    });

    // на страницах с новым хедером для моб версии отключаем показ виджетов и просчитываем vh
    if ($('.full-height-page-header').length) {
        // Просчитываем реальный vh для мобильных устройств (с учётом динамичных панелей браузера)
        const mobileViewport = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        mobileViewport();

        if (window.innerWidth <= 600) {
            changeAllWidgetsVisiblity(false);

            $(window).one('scroll', () => {
                changeAllWidgetsVisiblity(true);
            });
        }
    }

    // главная страница новая
    if ($('body').hasClass('page-homepageNew')) {
        // Переформировываем manage таб-лист чтобы на десктопе и мобле отличались
        if (window.innerWidth > 600) {
            const adminTabListNav = document.getElementById('admin__tab-list-nav');
            const adminTabScrollBar = document.getElementById('admin__tab-list-scroll');
            const adminTabListNavBtn = [...adminTabListNav.getElementsByTagName('button')];

            const initTabScrollBar = (list, scrollBar) => {
                list.forEach((el) => {
                    if (el.classList.contains('active')) {
                        scrollBar.style.left = `${el.offsetLeft}px`;
                        scrollBar.style.width = `${el.offsetWidth}px`;
                    };
                });
            };


            adminTabListNav.addEventListener('click', () => initTabScrollBar(adminTabListNavBtn, adminTabScrollBar));
            setTimeout(() => initTabScrollBar(adminTabListNavBtn, adminTabScrollBar), 1000); ;
        } else {
            const adminTabList = document.getElementById('admin__tab-list');
            const adminTabListItems = [...adminTabList.getElementsByClassName('admin__tab-item')];

            adminTabListItems.forEach((el) => el.classList.add('show', 'active'));
        }

        // Переформировываем аккордеон чтобы на десктопе работал как обычный блок
        if (window.innerWidth > 600) {
            const solutionsAccordion = document.getElementById('solutions-accordion');
            const accordionCollapses = [...solutionsAccordion.getElementsByClassName('collapse')];
            const accordionCollapseButtons = [...solutionsAccordion.querySelectorAll('[data-bs-toggle="collapse"]')];

            accordionCollapses.forEach((el) => el.classList.remove('collapse'));
            accordionCollapseButtons.forEach((el) => el.removeAttribute('data-bs-toggle'));
        }

        // Отключаем слайдер с отзывами на мобильной версии
        if (window.innerWidth <= 600 && $('#reviews__carousel').length) {
            const myCarousel = document.querySelector('#reviews__carousel');
            const carouselItems = [...myCarousel.getElementsByClassName('carousel-item')];

            myCarousel.classList.remove('carousel', 'slide');

            carouselItems.forEach((el) => el.classList.add('active', 'me-4'));
        }
    }

    $('.open-popup').magnificPopup({
        type: 'inline',
        preloader: false,
    });

    /*
        Skype-презентация
     */
    if ($('.order-skype-presentation-form').length) {
        var validate = new Validate($('.order-skype-presentation-form form')),
            $closeFormBtn = $('.order-skype-presentation-form .close-window'),
            $openFormBtn = $('.order-skype-presentation'),
            $form = $('.order-skype-presentation-form');

        $openFormBtn.on('click', showSkypePresentationForm);
        $closeFormBtn.on('click', function () {
            $form.hide();
        });

        if (window.location.hash.indexOf('orderskype') > -1) {
            showSkypePresentationForm();
        }

        function showSkypePresentationForm() {
            $form.show();

            if ($(document).width() <= 600) {
                $form.css({
                    top: window.scrollY
                });
            }
        }
    }

    /*
        Вход в аккаунт
     */
    if ($('.signin-form-modal').length) {
        const signinModal = document.getElementsByClassName('signin-form-modal')[0];
        ReactDOM.render(
            <SigninForm />,
            signinModal,
        );
    }

    // Отправляем событие в GA при регистрации
    $(window).bind('beforeunload', function () {
        if (($('.page-signup').length || $('.page-signup-finish').length)) {
            if (window.sendGaAfterRegSuccess) { // При перенаправлении на страницу в админке
                sendAnalytics('site', 'registrationFinished', 'registration', 'registration');
                // Закрыли страницу раньше и форма уже отправлена
            } else if (window.sendGaWhenFormSubmit) {
                sendAnalytics('site', 'registrationUnfinished', 'registration', 'registration');
                window.sendGaWhenFormSubmit = false;
            } else if (typeof window.sendGaWhenFormSubmit !== 'undefined') {
                window.sendGaWhenFormSubmit = true;
            }
        }
    });

    $('.popup-video').each(function () {
        var url = $(this).attr('href');

        $(this).magnificPopup({
            items: {
                src: url,
                type: 'iframe'
            }
        });
    });

    $('.show-all-features').bind('click', function () {
        $(this).next('.all-features').slideDown(200).end().remove();
    });

    /* Check if HTML5 video works */

    var canPlayVideo = false,
        v = document.createElement('video'),
        topVideoLang = {ru: 'ru', ua: 'ua', en: 'en', pl: 'pl'}[window.config.lang] || 'en';

    if (v.canPlayType && v.canPlayType('video/mp4').replace(/no/, '')) {
        canPlayVideo = true;
    }

    if (canPlayVideo) {
        $('.analytics').delegate('.item', 'mouseenter', function () {

            $(this).addClass('active').siblings().removeClass('active');

            $('.analytics .video')
                .attr('src', '/i/site/videos/' + $(this).data('video') + "-" + topVideoLang + '.mp4')
                .attr('poster', '/i/site/videos/' + $(this).data('video') + '-preview' + "-" + topVideoLang + '.jpg')
                .hide();

            setTimeout(function () {
                $('.analytics .video').show();
            }, 200);

        });

    } else {

        $('.analytics .video').remove();
        $('.analytics img').after('<img src="/i/site/videos/analytics-preview.jpg" class="video" width="400" height="250">');

        $('.analytics').delegate('.item', 'click', function () {
            $(this).addClass('active').siblings().removeClass('active');
            $('.analytics .video').attr('src', '/i/site/videos/' + $(this).data('video') + '-preview.jpg');

        });
    }

    function documentClickLang() {
        if (!$(this).closest('.dropdown').length) {
            $('.lang .dropdown').hide();
        } else {
            $(document).one('click', documentClickLang);
        }
    }


    function documentClickContact() {
        if (!$(this).closest('.contact-dropdown').length) {
            $('.contact .contact-dropdown').hide();
        } else {
            $(document).one('click', documentClickContact);
        }
    }

    const callMeBackSelector = $('.call-me-back form');
    callMeBackSelector.bind('submit', (e) => {
        const input = callMeBackSelector.find('input.phone'),
            phone = input.val();
        callMeBackSelector.find('button').attr('disabled', 'disabled');
        recaptcha3VRequest('callbackAction', { phone }, (params) => {
            input.attr('disabled', 'disabled');

            const callbackUrl = window.config.lang === 'ru' ? '/callback' : `/${window.config.lang}/callback`;
            $.post(callbackUrl, params, () => {
                callMeBackSelector.html(callMeBackSelector.find('.success').text());
            });
        });
        e.preventDefault();
        e.stopPropagation();
    });

    $('.tour-fiscal .who-needs-register .buttons-radio-group button').on('click', function () {
        var country = $(this).data('country'),
            $content = $('.who-needs-register .contents p[data-country="' + country + '"]');

        $content.addClass('active').siblings().removeClass('active');
        $(this).addClass('active').siblings().removeClass('active');
    });

    $('.open-tour-menu').on('click', () => {
        const $mobileTourMenu = $('.tour-top-menu-mobile');
        if ($mobileTourMenu.hasClass('menu-is-open')) {
            $mobileTourMenu.removeClass('menu-is-open');
        } else {
            $mobileTourMenu.addClass('menu-is-open');
        }
    });

    function loadVideo() {
        var topVideoLang = {ru: 'ru', ua: 'ua', en: 'en', pl: 'pl'}[window.config.lang] || 'en',
            videoTop = $('<video src="/i/site/videos/top-ipad-movie-' + topVideoLang + '.mp4" poster="/i/site/top-ipad-screenshot.jpg" autoplay="true" loop="true"></video>'),
            videoAnalytics = $('<video class="video" src="/i/site/videos/analytics-' + topVideoLang + '.mp4" autoplay="true" loop="true" width="400" height="250" poster="/i/site/videos/analytics-preview-' + topVideoLang + '.jpg"></video>');

        $('.ipad img').remove();
        $('.analytics .video-wrap .analytics-preview').remove();
        $('.ipad').append(videoTop);
        $('.analytics .video-wrap').append(videoAnalytics);
    }

    if ($(document).width() > 600) {
        loadVideo();
    }

    $(document).ready(function () {
        // cookie оффсета локального времени у пользователя. (используется для прописания
        // точной даты и времени предстоящих вебинаров и не только)
        if (!getCookie('user_timezone')) {
            // на один день ставим куки. на случай если человек ездит по миру
            let d = new Date();

            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            if (timezone == null) {
                // на случай, если с браузера пришло undefined ставим дефолтную куку на час
                timezone = 'Europe/Moscow';
                d.setTime(d.getTime() + (1*60*60*1000));
                setCookie('user_timezone', timezone, d.toUTCString());
            } else {
                d.setTime(d.getTime() + (24*60*60*1000));
                setCookie('user_timezone', timezone, d.toUTCString());
            }
        }

        $('.participant .place-photo').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            }
        });
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });

    $('#country').on('change', function (e) {
        if ($('#country').val() === 'US') {
            $('.state-usa').show();
        } else {
            $('.state-usa').hide();
        }
    });


    sbjs.init({
        domain: 'joinposter.com',
        isolate: true,
    });

    $('.hideCookies').bind('click', function (e) {
        e.preventDefault();

        const gdprAnalytics = Number($('.Cookies #gdpr_analytics').prop('checked'));
        const gdprAds = Number($('.Cookies #gdpr_ads').prop('checked'));

        setCookie('hide_gdpr_cookie_policy', '1');
        setCookie('gdpr_analytics', gdprAnalytics);
        setCookie('gdpr_ads', gdprAds);

        window.location.reload();
    });

    $('.Cookies-button.show-settings').bind('click', function (e) {
        e.preventDefault();
        $('.Cookies-footer-popup').css('bottom', '-300px');
        $('.Cookies-box').show();
    });

    $('.Cookies-box').bind('scroll', function () {
        const distanceToBottom = $('.Cookies-box').innerHeight() + $('.Cookies-box').scrollTop() -
            $('.Cookies-box')[0].scrollHeight;
        if (distanceToBottom === 0) {
            $('.Cookies-buttonWrap').addClass('scrolled');
        } else {
            $('.Cookies-buttonWrap').removeClass('scrolled');
        }
    });

    $('.Cookies-button.accept').bind('click', function (e) {
        e.preventDefault();
        console.log('accept');
        $('.Cookies-footer-popup').css('bottom', '-300px');
        setCookie('hide_gdpr_cookie_policy', '1');
        setCookie('gdpr_analytics', '1');
        setCookie('gdpr_ads', '1');

        window.location.reload();
    });

    $('a.change-language-link').bind('click', () => {
        let $a = $(this),
            lang = $a.data('lang');

        setCookie('site_lang', lang);
    });

    $(".landing-map-address").bind('click', function (event) {

        event.preventDefault();

        var id = $(this).attr('href'),
            top = $(id).offset().top - 70;

        $('body,html').animate({scrollTop: top}, top * 0.15);
    });


    /*
        Tabs switch in 1C page
     */
    $('.nav-tabs a[href^="#"]').on('click', function (e) {
        let $a = $(e.target);
        $('.nav-tabs a[href^="#"]').parents('li').removeClass('active');
        $a.parents('li').addClass('active');

        $('div[data-tab-link]').addClass('hide');
        $(`div[data-tab-link="${$a.attr('href')}"]`).removeClass('hide');
    });
    // При загруке включаем нужную вкладку
    if (window.location.hash) {
        $(`.nav-tabs a[href^="${window.location.hash}"]`).click();
    }

    /*
        Оборудование
     */
    if ($('.page-hardware').length) {

        $('.kit-type-selector button').on('click', function () {
            let $button = $(this),
                show = $button.data('show');

            $button.addClass('active').siblings('button').removeClass('active');

            $('.kit-hardware-wrapper.' + show).removeClass('hidden').siblings('.kit-hardware-wrapper').addClass('hidden');
        });

        function changeItemCount($item, diff) {
            let $popup = $item.parents('.buy-kit-popup-block'),
                count = Number($item.data('count')),
                maximum = Number($item.data('maximum')),
                $totals = $popup.find('.cost-hw'),
                total = $totals.eq(0).data('sum'),
                $discount = $popup.find('.discount-hw .discount-value'),
                discount = $discount.data('sum'),
                currency = $totals.eq(0).find('span').text();

            if (!isNaN(maximum) && count + diff > maximum) {
                return;
            }

            total += Number($item.data('price')) * diff;
            count += diff;
            discount += Number($item.data('annual-discount')) * diff;

            if (count > 0) {
                $item.addClass('active');
            } else {
                $item.removeClass('active');
            }

            $item.data('count', count);

            if (maximum !== 1) {
                $item.find('.count').html(count);
            }

            $discount
                .data('sum', discount)
                .html(`${formatSum(discount)} <span>${currency}</span>`);

            $totals
                .data('sum', total)
                .html(`${formatSum(total)} <span>${currency}</span>`);

            function formatSum(sum) {
                return sum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
            }
        }

        $('.add-to-kit .buy-with-price').on('click', function () {
            changeItemCount($(this), 1);
        });

        // в каждом вариативном выбираем первый как дефолтный
        $('.item-variants').each(function () {
            changeItemCount($(this).find('.buy-with-price:first-child'), 1);
        });

        $('.item-variants .buy-with-price').on('click', function () {
            changeItemCount($('.item-variants .buy-with-price.active'), -1);

            changeItemCount($(this), 1);
        });

        $('.add-to-kit .buy-with-price .delete').on('click', function (e) {
            changeItemCount($(this).parents('.buy-with-price'), -1);

            e.stopPropagation();
        });

        $('.finish-order-button').on('click', function () {
            let $popup = $(this).parents('.popup'),
                $add = $popup.find('.buy-select'),
                items = [];

            items.push({
                type: $popup.data('item-type'),
                name: $popup.data('item-name'),
                count: 1
            });

            $add.each(function () {
                let $item = $(this),
                    count = Number($item.data('count'));

                if (count > 0) {
                    items.push({
                        type: 'item',
                        name: $item.data('item-name'),
                        count: count
                    });
                }
            });

            $('.finish-order-popup-container input.order-contents').val(JSON.stringify(items));
        });

        let validateOrderForm = new Validate($('form.finish-order-popup'));

        $('.on-submit-order-hardware').click((e) => {
            e.preventDefault();
            const selfForm = $('.finish-order-popup'),
                tokenInput = selfForm.find('#orderHardwareToken');
            recaptcha3VRequest('orderHardware', {}, (params) => {
                if (params.token) {
                    tokenInput.val(params.token);
                    selfForm.submit();
                }
            });
        });
    }

    if ($('#order-skype-form').length) {
        $('#on-submit-order-skype').click((e) => {
            e.preventDefault();
            const selfForm = $('#order-skype-form'),
                tokenInput = selfForm.find('#orderSkypeToken');
            recaptcha3VRequest('orderSkypeCallback', {}, (params) => {
                if (params.token) {
                    tokenInput.val(params.token);
                    selfForm.submit();
                }
            });
        });
    }

    // обработчик для короткой формы
    if ($('.sign-up-short-form').length) {
        $('.sign-up-form-short-submit').click((e) => {
            e.preventDefault();
            const selfForm = $('.sign-up-short-form'),
                tokenInput = selfForm.find('#signUpShortToken');
            recaptcha3VRequest('signUpShortForm', {}, (params) => {
                if (params.token) {
                    tokenInput.val(params.token);
                    selfForm.submit();
                }
            }, true);
        });
    }

    /* Whitepaper popdown banner */
    if ($('.white-papers-popdown').length) {
        $(window).scroll(function () {
            const scroll = window.scrollY,
                postRect = $('.post-text').get(0).getBoundingClientRect(),
                $whitePapers = $('.white-papers-popdown');

            if (postRect.top + postRect.height - window.innerHeight <= 0) {
                $whitePapers.addClass('visible');
            } else {
                $whitePapers.removeClass('visible');
            }
        });

        $('.white-papers-popdown .close').on('click', function () {
            $(this).parents('.white-papers-popdown').remove();
        });
    }

    /* Whitepaper download form */
    if ($('.white-papers-form').length) {
        $('.white-papers-form').on('submit', function (e) {
            const $whitepaperForm = $(this),
                $errorBlock = $(this).find('.email-validation-error'),
                $successBlock = $('.blog-white-paper-form .success-message'),
                email = $whitepaperForm.find('.email').val().trim(),
                emailRegexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (emailRegexp.test(email)) {
                $errorBlock.addClass('d-none');
                $whitepaperForm.addClass('d-none');
                $successBlock.removeClass('d-none');
            } else {
                $errorBlock.removeClass('d-none');

                e.preventDefault();
                e.stopPropagation();
            }
        });
    }

    /* Blog subscribe form */
    if ($('.subscribe-blog-form').length) {
        $('.subscribe-blog-form form').each(function() {
            const $subscribeForm = $(this);

            new Validate($subscribeForm);

            $subscribeForm.on('submit', function (e) {
                e.preventDefault();

                const email = $subscribeForm.find('.email').val().trim();
                const $button = $subscribeForm.find('button');

                $button.attr('disabled', true).css('minWidth', $button.width()).addClass('sending');

                $.post($subscribeForm.attr('action'), {
                    email,

                }, (answer) => {
                    if (answer.status === 'success') {
                        $subscribeForm.parents('.blog-subscribe-block').find('.subscribe-block-error')
                            .removeClass('d-block')
                            .addClass('d-none');
                        $subscribeForm.parents('.blog-subscribe-block').find('.subscribe-block-success')
                            .removeClass('d-none')
                            .addClass('d-block');
                        $subscribeForm.hide();
                    } else if (answer.status === 'error' && answer.error === 'already_subscribed') {
                        $subscribeForm.parents('.blog-subscribe-block').find('.subscribe-block-error-already')
                            .removeClass('d-none')
                            .addClass('d-block');
                    } else if (answer.status === 'error' && answer.error === 'incorrect_email') {
                        $subscribeForm.parents('.blog-subscribe-block').find('.subscribe-block-error')
                            .removeClass('d-none')
                            .addClass('d-block');
                    }

                    $button.attr('disabled', false).css('minWidth', 55).removeClass('sending');
                });
            });

        });

    }

    //инициализация слайдеров
    $(".pos-slider .touchslider").touchSlider({
        container: this,
        duration: 350,
        delay: 3000,
        margin: 0,
        mouseTouch: true,
        namespace: "touchslider",
        pagination: ".touchslider-nav-item",
        currentClass: "active",
        autoplay: true,
        viewport: ".touchslider-viewport"
    });

    $(".macbook-slider .touchslider").touchSlider({
        container: this,
        duration: 350,
        delay: 3000,
        margin: 0,
        mouseTouch: true,
        namespace: "touchslider",
        pagination: ".touchslider-nav-item",
        currentClass: "active",
        autoplay: true,
        viewport: ".touchslider-viewport"
    });

    // Плавный скролл на лендинге хакатона
    $('.page-hack a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({scrollTop: target.offset().top}, 600);

                return false;
            }
        }
    });

    /* Короткая форма регистрации */
    if ($('.one-line-signup').length) {
        let $form = $('form.one-line-signup');

        $('.registration_privacy_policy_div').hide();

        $form.on('focusin', () => {
            $('.registration_privacy_policy_div').show();
        });

        $form.each(function () {
            let validate = new Validate($(this));

            const $phoneInput = $(this).find('.phone');

            $(this).one('focusin', () => {
                $phoneInput.removeAttr('placeholder');
                initPhoneInput($phoneInput, validate);
            });
        });

        $form.find('.place-name').on('input', (e) => {
            let name = $(e.target).val();

            $form.find('.url').val(toTranslit(name));
        });

        // обработчикдля формы  в одну строку
        $('.sign-up-line-form-submit').click((e) => {
            e.preventDefault();
            const selfForm = $('.one-line-signup'),
                tokenInput = selfForm.find('.signUpLineFormToken');
            recaptcha3VRequest('signUpLineFormSubmit', {}, (params) => {
                if (params.token) {
                    tokenInput.val(params.token);
                    selfForm.submit();
                }
            }, true);
        });

        $form.on('submit', function (e) {
            var $button = $(this).find('.primary');

            if (!$button.hasClass('disabled')) {
                $button
                    .addClass('disabled')
                    .attr('disabled', 'disabled')
                    .find('.spinner-container')
                    .css('display', 'block');
            }
        });
    }

    /* Форма заявки на демо */
    if ($('.get-demo-form').length) {
        const $getDemoForms = $('form.get-demo-form');

        $getDemoForms.each(function () {
            const $getDemoForm = $(this);
            const getDemoValidate = new Validate($getDemoForm);
            const $phoneInput = $getDemoForm.find('.phone');
            let intlGetDemo;

            $getDemoForm.one('focusin', () => {
                $phoneInput.removeAttr('placeholder');
                intlGetDemo = initPhoneInput($phoneInput, getDemoValidate);
            });

            $getDemoForm.on('submit', (e) => {
                e.preventDefault();
                const $button = $getDemoForm.find('.primary');

                if (!$button.hasClass('disabled')) {
                    $button
                        .addClass('disabled')
                        .addClass('sending')
                        .attr('disabled', 'disabled')
                        .find('.spinner-container')
                        .css('display', 'block');
                    const parameters = {
                        name: $getDemoForm.find('input[name="name"]').val(),
                        email: $getDemoForm.find('input[name="email"]').val(),
                        phone: intlGetDemo.getNumber(),
                        business_status: $getDemoForm.find('select[name="business_status"]').val(),
                        role: $getDemoForm.find('select[name="role"]').val(),
                    };
                    recaptcha3VRequest('demoCallbackAction', parameters, (params) => {
                        $.post($getDemoForm.attr('action'), params, (answer) => {
                            if (answer.status === 'success') {
                                $getDemoForm.addClass('finished');
                                $getDemoForm.find('.callback-time').html(answer.callbackString);
                            } else {
                                $button.attr('disabled', false).removeClass('sending');
                            }
                        });
                    });
                }
            });
        });
    }

    /* Форма регистрации дилера */
    if ($('.signup-form-dealer').length) {
        let $form = $('form.signup-form-dealer');

        $form.each(function () {
            let validate = new Validate($(this));
        });

        $('#sign-up-form-dealer-submit').click((e) => {
            e.preventDefault();
            const tokenInput = $form.find('#signUpFormDealerToken');
            recaptcha3VRequest('signUpFormDealerSubmit', {}, (params) => {
                if (params.token) {
                    tokenInput.val(params.token);
                    $form.submit();
                }
            });
        });

        $form.on('submit', function (e) {
            var $button = $(this).find('.primary');

            if (!$button.hasClass('disabled')) {
                $button
                    .addClass('disabled')
                    .attr('disabled', 'disabled')
                    .find('.spinner-container')
                    .css('display', 'block');
            }
        });
    }

    /* Форма регистрации парртнера новая */
    if ($('.new-partner-form').length) {
        $('#new-partner-submit').click((e) => {
            e.preventDefault();
            const selfForm = $('.new-partner-form'),
                tokenInput = selfForm.find('#newPartnerToken');
            recaptcha3VRequest('newPartnerSubmit', {}, (params) => {
                if (params.token) {
                    tokenInput.val(params.token);
                    selfForm.submit();
                }
            });
        });
    }

    /* Форма регистрации на реф. программу */
    if ($('.signup-form-referral').length) {
        let $form = $('form.signup-form-referral');

        $form.each(function () {
            let validate = new Validate($(this));
        });

        $('#sign-up-form-referral-submit').click((e) => {
            e.preventDefault();
            const selfForm = $('.signup-form-referral'),
                tokenInput = selfForm.find('#signUpFormReferralToken');
            recaptcha3VRequest('signUpFormReferralSubmit', {}, (params) => {
                if (params.token) {
                    tokenInput.val(params.token);
                    selfForm.submit();
                }
            });
        });

        $form.on('submit', function (e) {
            var $button = $(this).find('.primary');

            if (!$button.hasClass('disabled')) {
                $button
                    .addClass('disabled')
                    .attr('disabled', 'disabled')
                    .find('.spinner-container')
                    .css('display', 'block');
            }
        });
    }

    /* Форма получения доступа к материалам академии */
    if ($('.academy-lead-form').length) {
        $('a.academy-item-link').bind('click', function (e) {
            const $a = $(this);

            if (getCookie('academy_lead') !== 'true' && getCookie('account_url') === null) {
                e.preventDefault();

                $.magnificPopup.open({
                    items: {
                        src: $a.data('popup-id'),
                        type: 'inline',
                    },
                });
            }
        });

        const $forms = $('form.academy-lead-form');

        $forms.each(function () {
            const $academyForm = $(this);
            const academyFormValidate = new Validate($academyForm);
            const $phoneInput = $academyForm.find('.phone-input');
            const $businessTypeSelect = $academyForm.find('select[name="business_type"]');
            let intl;

            $academyForm.one('focusin', () => {
                $phoneInput.removeAttr('placeholder');
                intl = initPhoneInput($phoneInput, academyFormValidate);
            });

            $businessTypeSelect.on('change', () => {
                const index = $businessTypeSelect.find('option:selected').index();
                const $rowEmployeesNumber = $academyForm.find('.row-employees-number');

                if (index < 3) {
                    $rowEmployeesNumber.hide();
                } else {
                    $rowEmployeesNumber.show();
                }
            });

            $academyForm.on('submit', (e) => {
                e.preventDefault();

                const $button = $academyForm.find('button.primary');

                if ($button.attr('disabled') === 'disabled') {
                    return;
                }

                $button.attr('disabled', true).addClass('sending');

                const parameters = {
                    item: $academyForm.find('.academy-lead-form-h2').text(),
                    name: $academyForm.find('input[name="name"]').val(),
                    phone: intl.getNumber(),
                    email: $academyForm.find('input[name="email"]').val(),
                    business_type: $academyForm.find('select[name="business_type"]').val(),
                    employees_number: $academyForm.find('select[name="employees_number"]').val(),

                };
                recaptcha3VRequest('newAcademyLeadAction', parameters, (params) => {
                    $.post($academyForm.attr('action'), params, (answer) => {
                        if (answer.status === 'success') {
                            if ($academyForm.parents('.academy-overlay').length) {
                                $academyForm.parents('.academy-overlay').remove();
                                $.magnificPopup.close();
                            } else {
                                window.location.href = $academyForm.data('href');
                            }
                        } else {
                            $button.attr('disabled', false).removeClass('sending');
                        }
                    });
                });
            });
        });
    }

    /* Форма заказа звонка (из футера и мобильного хедера) */
    if ($('#call-me-modal').length) {
        const $form = $('form.call-me-form');
        const formInput = $('form.call-me-form input[name=phone]');
        const formButton = $('form.call-me-form button[type=submit]');
        const form = new Validate($form);
        let intl;

        $form.each(function () {
            let validate = new Validate($(this));
        });

        // intl-phone-input
        const phoneInput = $('#call-me-modal .intl-phone-input');

        $(phoneInput).one('focusin', () => {
            phoneInput.removeAttr('placeholder');
            intl = initPhoneInput(phoneInput, form);
        });

        $(phoneInput).on('focus', () => {
            phoneInput.removeClass('input-error');
        });

        $('#call-me-modal button[type=submit]').click((e) => {
            e.preventDefault();
            const tokenInput = $form.find('#signUpFormDealerToken');

            if (phoneInput.val() && !phoneInput.hasClass('input-error') && !phoneInput.hasClass('intl-invalid')) {
                recaptcha3VRequest('signUpFormDealerSubmit', {}, (params) => {
                    if (params.token) {
                        tokenInput.val(params.token);
                        $form.submit();
                    }
                });
            } else {
                phoneInput.addClass('input-error');
            }
        });

        $form.on('submit', function (e) {
            const phone = intl.getNumber();
            if (!formButton.hasClass('disabled')) {
                formButton
                    .addClass('disabled')
                    .attr('disabled', 'disabled');
            }
            recaptcha3VRequest('callbackAction', { phone }, (params) => {
                formInput.attr('disabled', 'disabled');

                const callbackUrl = window.config.lang === 'ru' ? '/callback' : `/${window.config.lang}/callback`;
                $.post(callbackUrl, params, () => {
                    $form.html($form.find('.success').text());
                });
            });
            e.preventDefault();
            e.stopPropagation();
        });
    }

    $(".case-slider .touchslider").touchSlider({
        container: this,
        duration: 350,
        delay: 3000,
        margin: 0,
        mouseTouch: true,
        namespace: "touchslider",
        pagination: ".touchslider-nav-item",
        currentClass: "active",
        autoplay: true,
        viewport: ".touchslider-viewport",
        next: ".slider-switch.next",
        prev: ".slider-switch.prev",
    });

    if ($('.application .screenshots').length) {
        // таймаут из-за бага когда картинки не успевают загрузиться
        setTimeout(() => {
            // Карусель скриншотов
            // Загружаем на странице из CDN чтобы не увеличивать размер JS
            new Swiper('.swiper-container', {
                slidesPerView: 'auto',
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }, 500);

        // Показываем картинку в модалке по клику
        $('.screenshots .swiper-container img').on('click', (e) => {
            const src = e.target.src;
            const items = [];
            const options = {
                captionEl: false,
                bgOpacity: 0.6,
                tapToClose: true,
                history: false,
                getThumbBoundsFn() {
                    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    const rect = e.target.getBoundingClientRect();

                    return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
                }
            };

            $('.swiper-container .swiper-slide').each((i, img) => {
                if (img.src === src) {
                    options.index = i;
                }

                items.push({
                    src: img.src,
                    msrc: img.src,
                    w: img.offsetWidth * 1.6,
                    h: img.offsetHeight * 1.6,
                });
            });

            // PhotoSwipe, PhotoSwipeUI_Default загружаем из CDN на самой странице
            const gallery = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, items, options);
            gallery.init();
        });
    }

    if ($('.pricing')) {
        $('.horeca-retail-selector button').on('click', function () {
            let $button = $(this),
                show = $button.data('show');

            $button.addClass('active').siblings('button').removeClass('active');

            if (show === 'retail') {
                $('.show-only-horeca').hide();
                $('.show-only-retail').show();

            } else {
                $('.show-only-horeca').show();
                $('.show-only-retail').hide();
            }
        });

        $('.year-month-selector button').on('click', function () {
            let $button = $(this),
                show = $button.data('show');

            $button.addClass('active').siblings('button').removeClass('active');

            if (show === 'year') {
                $('.show-only-month').hide();
                $('.show-only-year').show();

            } else {
                $('.show-only-month').show();
                $('.show-only-year').hide();
            }
        });

        if ($('.testimonials').length > 0) {
            function fitTestimonialsSlider() {
                var maxHeight = 0;

                $('.testimonials .touchslider-item').each(function(){
                    var thisH = $(this).height();
                    if (thisH > maxHeight) { maxHeight = thisH; }
                });
                $('.testimonials .touchslider-viewport').height(maxHeight + 20);
            }

            $('.touchslider').touchSlider({
                duration: 300,
                delay: 15000,
                autoplay: true,
            });

            fitTestimonialsSlider();
            setTimeout(function () {
                fitTestimonialsSlider();
            },500);

            $(window).on('resize', function () {
                fitTestimonialsSlider();
            });
        }

        if ($('.additional-selector').length > 0) {
            $('.additional-selector button').on('click', function () {
                let $button = $(this),
                    show = $button.data('show');

                $button.addClass('active').siblings('button').removeClass('active');

                $('.additional .tab').hide();
                $('.additional .tab.' + show).show();
            });
        }

        if ($('.pricing_blocks_mobile').length > 0) {
            $('.plan .header').on('click', function () {
                const plan = $(this).parent(),
                    header = $('.header-container').height();

                $('.plan').not(plan).each(function(){
                    $(this).removeClass('active');
                });

                plan.toggleClass('active');
                $('html, body').animate({ scrollTop: plan.position().top - header }, 0);
            });

            // открываем коллапс
            $('.expand-features').on('click', function () {
                const button = $(this);

                button.prev().addClass('active');
                button.fadeOut();
            });

            // tooltip моб. версии
            $('.feature-row').on('click', function () {
                const row = $(this),
                    block = $(`.popover__wrapper[tooltip-name="${row.attr('data-tooltip')}"]`);

                if (block.length > 0) {
                    block.addClass('active');
                }
            });

            $('.popover__wrapper, .popover__wrapper .close').on('click', function () {
                if ($(this).hasClass('active') || $(this).hasClass('close')) {
                    $('.popover__wrapper').removeClass('active');
                }
            });
        }
    }

    // Логика для попапа на мобле, у прайсинга она другая
    if ($('.pricing').length === 0) {
        $('.popover__container, .popover__container .close').on('click', function () {
            const block = $(this).find('.popover__wrapper');

            if (block.length > 0) {
                block.toggleClass('active');
            }
        });
    }

    $('.dropdown-54-fz-toggle').click(function (e) {
        let dropdown = $(e.target).parent().find('.dropdown-54-fz');
        if (dropdown.hasClass('open')) {
            dropdown.addClass('close').removeClass('open');
            $(e.target).removeClass('open-arrow');
        } else {
            dropdown.addClass('open').removeClass('close');
            $(e.target).addClass('open-arrow');
        }
    });

    $(".fz-list li a").bind('click', function (event) {

        event.preventDefault();

        var id = $(this).attr('href'),
            top = $(id).offset().top - 90;

        $('body,html').animate({scrollTop: top}, top * 0.15);
    });

    $(".careers a.button-job").bind('click', function (event) {
        event.preventDefault();

        var id = $(this).attr('href'),
            top = $(id).offset().top;

        $('body,html').animate({scrollTop: top}, top * 0.15);
    });

    if (['ru', 'ua'].indexOf(window.config.lang) > -1) {
        console.log('%cHey! You like to hack stuff? Come join our team and let’s do it together: https://joinposter.com/jobs', 'font-size:18px;');
    }

    // Баннер про телеграм канал на время коронавируса
    if ($('.telegram-channel-banner').length) {
        $('.telegram-channel-banner .hide-after-click').bind('click', () => {
            setCookie('telegram_channel_banner_closed', '1');
            $('.telegram-channel-banner').remove();
        });
    }

    /**
     * Открываем новую форму регистрации в попапе
     * @param launchedFromSignupURL — была загрузка страницы /signup по прямому переходу?
     * @param createAccountData — данные первого шага регистрации, используется в короткой форме
     * @param urlCompanyType - передаём этот параметр через открытую форму 'open-fullscreen'
     */
    const openSignupForm = (launchedFromSignupURL, createAccountData = false, urlCompanyType = false) => {
        const fullscreenSignupFormContainer = document.getElementById('fullscreen-signup-form-container');
        const previousHref = window.location.href;
        const scrollY = window.scrollY;

        if (!urlCompanyType) {
            // смотрим параметры в строке, выдёргиваем company_type и передаем далее
            const hrefParts = previousHref.split('?');

            if (hrefParts.length > 1) {
                const params = new URLSearchParams(hrefParts[1]);
                if (params.get('company_type')) {
                    urlCompanyType = params.get('company_type');
                }
            }
        }

        $('.main-container').hide();
        $('#footer').hide();

        // Прячем Binotel и Intercom/FB Messenger
        if (isMobile()) {
            changeAllWidgetsVisiblity(false);
        } else {
            changeBinotelWidgetVisiblity(false);
        }

        // Отправляем хит в GA
        if (!launchedFromSignupURL) {
            virtualPageView(`${window.config.langUrl}/signup`);
        }

        ReactDOM.render(
            <SignupForm
                popup={!launchedFromSignupURL}
                startWithSecondStep={Boolean(createAccountData)}
                createAccountData={createAccountData}
                urlCompanyType={urlCompanyType}
                onClose={() => {
                    // Если открывали по урлу, редиректом отправляем на предыдущую страницу или на главную
                    if (launchedFromSignupURL) {
                        if (document.referrer !== '' && !isSignupUrl(document.referrer)) {
                            window.location.href = document.referrer;
                        } else {
                            window.location.href = window.config.langUrl === '' ? '/' : window.config.langUrl;
                        }

                        return false;
                    }

                    // Возвращаем урл
                    virtualPageView(previousHref);

                    // Возвращаем страницу
                    $('.main-container').show();
                    $('#footer').show();

                    ReactDOM.unmountComponentAtNode(fullscreenSignupFormContainer);

                    // Возвращаем скролл на место
                    window.scrollTo(0, scrollY);

                    // Возвращаем все виджеты
                    if (isMobile()) {
                        changeAllWidgetsVisiblity(true);
                    }
                }}
            />,
            fullscreenSignupFormContainer,
        );
    };

    // Обрабатываем на клик на кнопку открытия новой формы регистрации
    $('button.open-fullscreen, .open-fullscreen-signup').on('click', function (e) {
        // открываем попап только если адрес не заблокирован или страна не ZA (редирект и заглушка)
        if (!window.config.accessByIp && window.config.country !== 'ZA') {
            const href = this.tagName === 'A' ? this.href : $(this).parents('a').attr('href');

            // Когда у кнопки регистрации есть ref-ссылка, значит будем делать жесткий редирект,
            // чтобы рефералка записалась в куку. Та же логика для клика с Ctrl/Cmd — открытие в новой вкладке
            // Иначе — открываем форму моментально джаваскриптом
            if (href.indexOf('?ref=') === -1 && !e.ctrlKey && !e.metaKey) {
                e.stopPropagation();
                e.preventDefault();

                let urlCompanyType = false;

                // смотрим параметры в строке, выдёргиваем company_type и передаем далее
                const hrefParts = href.split('?');

                if (hrefParts.length > 1) {
                    const params = new URLSearchParams(hrefParts[1]);
                    if (params.get('company_type')) {
                        urlCompanyType = params.get('company_type');
                    }
                }

                openSignupForm(false, false, urlCompanyType);
            }
        }
    });

    // Если пользователь попал напрямую на страницу регистрации по урлу, сразу рендерим компоненту
    // не позволяем открывать форму регистрации в попапе для заблокированных ip и ЮАР (для юара заглушка)
    if (isSignupUrl(window.location.pathname) && !window.config.accessByIp && window.config.country !== 'ZA') {
        // В новом сайте на next есть короткая форма регистрации
        // Она по сабмиту редиректит на /signup?signupData=... и JSON-ом прокидывает данные реги
        if (window.location.search && window.location.search.indexOf('?signupData=') === 0) {
            let signupData = window.location.search.substring('?signupData='.length);
            signupData = JSON.parse(decodeURIComponent(signupData));

            openSignupForm(true, signupData);
        } else {
            openSignupForm(true);
        }
    }

    // Рендерим однострочные формы регистрации
    document.querySelectorAll('.one-line-signup-new-container').forEach((container) => {
        if ($(container).closest('.start-testing-form').length > 0) {
            ReactDOM.render(
                <StepAccount
                    inlineForm
                    submitButtonText={l('site.signupForm.submitTry')}
                    createAccount={(data) => {
                        openSignupForm(false, data);
                    }}
                />,
                container,
            );
        }
        else {
            ReactDOM.render(
                <StepAccount
                    inlineForm
                    createAccount={(data) => {
                        openSignupForm(false, data);
                    }}
                />,
                container,
            );
        }
    });

    // показ всплывающей формы регистрации на странице если клиент ранее не заполнял ее и у него не стоит соответствующая кука
    if ($('#popup-academy-lead-form.on-page').length > 0) {
        $('#popup-academy-lead-form.on-page ~ #popup-trigger').on('click', function () {
            $.magnificPopup.open({
                items: {
                    src: $('#popup-academy-lead-form.on-page'),
                    type: 'inline',
                },
                closeOnContentClick: false,
                closeOnBgClick: false,
                closeBtnInside: false,
                showCloseBtn: false,
                enableEscapeKey: false,
            });
        });

        $('#popup-academy-lead-form.on-page ~ #popup-trigger').click();
    }

    // обработка нажатия на кнопку demo. собираем контакты
    const $demoLinkSelector = $('a.demo-link');
    if ($demoLinkSelector.length > 0) {
        // если не авторизован или нету куки о собранном ранее контакте таким образом -- показываем форму
        if (getCookie('demo_contact_form') === null && getCookie('account_url') === null) {
            const gatherContactModal = new bootstrap.Modal('#gather-contact');

            // Рендерим форму сбора контактов для демо
            ReactDOM.render(
                <GatherContactForm
                    cookieName="demo_contact_form"
                    referral="demo_contact_form"
                    comment="Вход в демо аккаунт"
                    linkTo={$demoLinkSelector.attr('href')}
                    submitText={l('site.gatherContactForm.demoSubmit')}
                    blank
                />,
                $('div.gather-contact-form')[0],
            );

            $demoLinkSelector.on('click', (e) => {
                e.preventDefault();

                gatherContactModal.show();
            });
        };
    }

    // Рендерим блок случайного отзыва
    document.querySelectorAll('.random-testimonial-block').forEach((container) => {
        ReactDOM.render(
            <TestimonialBlock />,
            container,
        );
    });

    const signUpModal = document.getElementById('signInForm');

    function loadIntercom() {
        // что бы не падало на локалке
        if (!window.intercomSettings) return;
        const { APP_ID: app_id, blacklisted } = window.intercomSettings;

        if (blacklisted) return;

        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', window.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments)
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args)
                };
                w.Intercom = i;

                function l() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                }

                if (document.readyState === 'complete') {
                    l()
                } else {
                    if (w.attachEvent) {
                        w.attachEvent('onload', l);
                    } else {
                        w.addEventListener('load', l, false);
                    }
                }
            }
        })();

        window.removeEventListener('scroll', loadIntercom);
        if (signUpModal) {
            // убиваем ивент если уже один раз инициализировали
            signUpModal.removeEventListener('shown.bs.modal', loadIntercom);
        }
    }

    window.addEventListener('scroll', loadIntercom);

    if (signUpModal) {
        // если есть модалка то вешаем событие на открытие модалки.
        // инициализируем библиотеку
        signUpModal.addEventListener('shown.bs.modal', loadIntercom);
    }
});
