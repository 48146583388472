/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StepAccount from './stepAccount';
import StepBusinessInfo from './stepBusinessInfo';
import StepTallyForm from './stepTallyForm';
import StepPreloader from './stepPreloader';
import LocalizedImage from '../localizedImage';
import l from '../../helpers/siteLanguage';
import { virtualPageView } from '../../helpers/siteHelper';

const STEP_ACCOUNT_DATA = 'accountData';
const STEP_BUSINESS_INFO = 'businessInfo';
const STEP_PRELOADER = 'preloader';
const STEP_ERROR = 'error';
const PRELOADER_STEP_MIN_TIME = 2000;

export default class SignupForm extends React.Component {
    propTypes = {
        onClose: PropTypes.func.isRequired,
        startWithSecondStep: PropTypes.bool.isRequired,
        createAccountData: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
        popup: PropTypes.bool.isRequired,
        urlCompanyType: PropTypes.number,
    };

    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search),
            isTallyAvailable = window.config.abTally || urlParams.has('tally');

        // Если передается createAccountData, значит компонента создается после короткой формы регистрации,
        // и можно сразу показывать второй шаг в форме
        this.state.step = props.createAccountData || urlParams.has('tally') ? STEP_BUSINESS_INFO : STEP_ACCOUNT_DATA;
        this.state.isTallyAvailable = isTallyAvailable;
    }

    state = {
        accountCreated: false,
        businessInfoData: false,
        redirectUrl: '',
        clientId: 0,
        clientUrl: '',
        hasTallyLoadError: false,
    };

    componentDidMount() {
        const { createAccountData } = this.props;

        // Если передается createAccountData, значит компонента создается после короткой формы регистрации,
        // и можно сразу начинать регистрацию
        if (createAccountData) {
            this.createAccount(createAccountData);
        }
    }

    /**
     * Записываем данные со второго шага, которые будем отправлять
     * после того как закончится создание аккаунта
     * @param data
     */
    setBusinessInfoData = (data) => {
        this.setState({
            businessInfoData: data,
        }, () => {
            if (this.state.accountCreated) {
                this.sendBusinessInfo();
            }
        });
    };

    /**
     * Показать ошибку регистрации
     */
    showError = () => {
        virtualPageView(`${window.config.langUrl}/signup/error`);

        this.setState({
            step: STEP_ERROR,
        });
    };

    /**
     * Отправляем данные со второго шага
     */
    sendBusinessInfo = () => {
        // Покрутим немного спиннер для плавности интерфейса,
        // перед тем как отправить на урл signup/finish
        setTimeout(() => {
            if (this.state.step !== STEP_ERROR) {
                this.setState({
                    step: STEP_PRELOADER,
                });
            }
        }, 500);

        const startTime = new Date().getTime();
        const { clientId, businessInfoData, hasTallyLoadError } = this.state;

        $.ajax({
            type: 'POST',
            url: `${window.config.langUrl}/signup/ajax/setup`,
            data: {
                clientId,
                info: { ...businessInfoData, hasTallyLoadError },
            },
            success: (answer) => {
                if (!answer.setupSuccess) {
                    this.showError();
                    return;
                }

                const timeDiff = new Date().getTime() - startTime;

                // Мы хотим, чтобы какое-то время форма побыла на экране прелоадера, чтобы отправился ивент в GA
                if (timeDiff < PRELOADER_STEP_MIN_TIME) {
                    setTimeout(this.redirectToAccount, PRELOADER_STEP_MIN_TIME - timeDiff);
                } else {
                    this.redirectToAccount();
                }
            },
            error: this.showError,
        });
    };

    /**
     * После завершения регистрации и настройки аккаунта — отправляем в админку
     */
    redirectToAccount = () => {
        window.location.href = this.state.redirectUrl;
    };

    /**
     * Отправляем данные первого шага регистрации, чтобы создать аккаунт
     * @param data
     */
    createAccount = (data) => {
        // добавили phoneRaw и intTelInput для отслеживания регистраций без пришедшего телефона
        // phoneRaw - необработанный номер телефона пользователя
        // phoneUtilsLoaded - флаг для того чтобы понять был прогружен инпут в определенный случай или нет
        const {
            name, country, phone, email, ownerName, password, businessName, url, acceptPrivacyPolicy,
            phoneRaw, phoneUtilsLoaded, token, inlineForm,
        } = data;

        this.setState({ step: STEP_BUSINESS_INFO, clientUrl: url });

        if (inlineForm) {
            this.onLoadTallyError();
        }

        $.ajax({
            type: 'POST',
            url: `${window.config.langUrl}/signup/ajax`,
            data: {
                short: true,
                name,
                ownerName,
                country,
                phone,
                phoneRaw,
                phoneUtilsLoaded,
                email,
                password,
                url,
                place_name: businessName,
                lang: window.config.lang,
                acept_privacy_policy: acceptPrivacyPolicy,
                token,
                inlineForm: Number(inlineForm || 0),
            },
            success: (answer) => {
                if (!answer.redirect_url) {
                    this.showError();
                    return;
                }

                this.setState({
                    accountCreated: true,
                    redirectUrl: answer.redirect_url,
                    clientId: answer.client_id,
                }, () => {
                    // Если к моменту окончания регистрации пользователь уже заполнил второй шаг,
                    // сразу отправляем данные для настройки аккаунта
                    if (this.state.businessInfoData) {
                        this.sendBusinessInfo();
                    }
                });
            },
            error: this.showError,
        });
    };

    onLoadTallyError = () => {
        this.setState({ isTallyAvailable: false, hasTallyLoadError: true });
    }

    setPreloaderStep = () => {
        this.setState({
            step: STEP_PRELOADER,
        });
    }

    render() {
        const {
            onClose,
            popup,
            startWithSecondStep,
            urlCompanyType,
        } = this.props;
        const { step, isTallyAvailable, clientUrl } = this.state;

        return (
            <div className={classNames(['fullscreen-signup-form'], { popup })}>
                <div className="background" />

                <div className="width-wrapper">
                    <div className="row">
                        <div className="col-xs-12 col-sm-3 left">
                            <div className="logo" />

                            <div className="about-poster">
                                <h3 dangerouslySetInnerHTML={{ __html: l('site.signupForm.sidebarHeader') }} />
                                <p>{l('site.signupForm.sidebarSubheader')}</p>
                            </div>

                            <div className="poster-illustration">
                                <LocalizedImage
                                    availableSlugs={['en-usd', 'pl-pln', 'ru-rub', 'ru-uah', 'ua-uah']}
                                    src="/i/site/tour/boss/boss-iphone.png"
                                    country={window.config.country}
                                    language={window.config.lang}
                                    className="poster-boss"
                                    doubleSize={false}
                                />

                                <div className="pos">
                                    <img
                                        src="/i/site/index/ipad.png"
                                        className="ipad"
                                        alt="ipad"
                                    />

                                    <LocalizedImage
                                        availableSlugs={['en-usd', 'pl-pln', 'ru-rub', 'ru-uah', 'ua-uah']}
                                        src="/i/site/index/screens/terminal.png"
                                        country={window.config.country}
                                        language={window.config.lang}
                                        className="ipad-app"
                                        doubleSize={false}
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-xs-12 col-sm-5 col-sm-offset-2"
                        >
                            { step === STEP_ACCOUNT_DATA && (
                                <StepAccount
                                    createAccount={this.createAccount}
                                    isTallyAvailable={isTallyAvailable}
                                />
                            )}

                            { step === STEP_BUSINESS_INFO && !isTallyAvailable && (
                                <StepBusinessInfo
                                    setBusinessInfoData={this.setBusinessInfoData}
                                    askPassword={startWithSecondStep}
                                    urlCompanyType={urlCompanyType}
                                />
                            )}

                            { step === STEP_BUSINESS_INFO && isTallyAvailable && (
                                <StepTallyForm
                                    setBusinessInfoData={this.setBusinessInfoData}
                                    onLoadTallyError={this.onLoadTallyError}
                                    setPreloaderStep={this.setPreloaderStep}
                                    clientUrl={clientUrl}
                                />
                            )}

                            { step === STEP_PRELOADER && <StepPreloader />}

                            { step === STEP_ERROR && (
                                <div className="signup-error">
                                    {l('site.signupForm.signupError')}
                                </div>
                            )}
                        </div>
                    </div>

                    { step === STEP_ACCOUNT_DATA && (
                        <div className="close" onClick={onClose}>
                            &times;
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
