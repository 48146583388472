import React, { Component } from 'react';
import PosterUiKit from 'poster-ui-kit';
import PropTypes from 'prop-types';
import { virtualPageView } from '../../helpers/siteHelper';
import l from '../../helpers/siteLanguage';
import { TALLY_FORM_EVENT_FORM_SUBMITTED, TALLY_FORM_EVENT_ORIGIN } from '../../../consts/site/signup';

export default class StepTallyForm extends Component {
    propTypes = {
        setBusinessInfoData: PropTypes.func.isRequired,
        onLoadTallyError: PropTypes.func.isRequired,
        setPreloaderStep: PropTypes.func.isRequired,
        clientUrl: PropTypes.string.isRequired,
    };

    defaultProps = {
        onLoadTallyError: () => {},
    }

    componentDidMount() {
        // todo: нужно поменять для tally формы?
        virtualPageView(`${window.config.langUrl}/signup/step2`);

        this.loadTally();

        this.handleTallyResponse();
    }

    loadTally = () => {
        const { onLoadTallyError } = this.props;
        const script = document.createElement('script');
        script.src = 'https://tally.so/widgets/embed.js';
        script.id = 'tally';
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            // eslint-disable-next-line no-undef
            try {
                Tally.loadEmbeds();
            } catch (error) {
                onLoadTallyError(error);
            }
        };
        script.onerror = onLoadTallyError;
    }

    /**
     * Обработчик ответа от tally
     */
    processTallyResponse = (fields) => {
        const { setPreloaderStep } = this.props;
        setPreloaderStep();

        const defaultParams = [
            'companyType',
            'businessType',
            'businessStatus',
            'employeesNumber',
            'serviceModeFullService',
            'serviceModeQuickService',
            'serviceModeDelivery',
            'serviceModeTakeaway',
        ];

        const formData = fields.reduce((acc, field) => {
            const { type, title, answer: { value = '' } = {} } = field;
            const key = defaultParams.includes(title) ? 'default' : 'custom';

            if (value === '') {
                return acc;
            }

            if (type === 'CALCULATED_FIELDS') {
                acc[key][title] = value;
            } else if (type === 'MULTIPLE_CHOICE') {
                acc.logInfo[title.trim()] = value.trim();
            }

            return acc;
        }, { default: {}, custom: {}, logInfo: {} });

        // todo: на беке дефолтные значения
        // тут просто перебрасываем всё что есть с телли на бек, ничего не конвертируем не проверяем. всё на беке??
        this.props.setBusinessInfoData({
            // ownerName: '',
            // password: '',
            // promoCode: '',
            ...formData.default,
            customProperties: formData.custom,
            logInfo: formData.logInfo,
        });
    };

    handleTallyResponse = () => {
        const { onLoadTallyError } = this.props;

        window.addEventListener('message', (event) => {
            if (typeof event.data === 'string' && event.origin === TALLY_FORM_EVENT_ORIGIN) {
                const response = JSON.parse(event.data);
                try {
                    if (response.event === TALLY_FORM_EVENT_FORM_SUBMITTED) {
                        this.processTallyResponse(response.payload.fields);
                    }
                } catch (e) {
                    onLoadTallyError(e);
                }
            }
        });
    }

    render() {
        const { clientUrl } = this.props;
        const tallySrc = `${l('site.signupForm.tallyFormUrl')}?host=${document.location.host}&country=${window.config.country}`
            + `&clientUrl=${clientUrl}`;

        return (
            <>
                <PosterUiKit.BlockTitle
                    title={l('site.signupForm.header')}
                    hint={`${l('site.signupForm.stepXofN', { step: 2, total: 2 })}:
                                            ${l('site.signupForm.stepSetupAccount')}`}
                />

                <iframe
                    className="tally"
                    data-tally-src={tallySrc}
                    width="100%"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title="joinposter.com"
                />
            </>
        );
    }
}
