// Какие страны показывать сверху списка в компоненте ввода номера телефона,
// в зависимости от языка сайта
const PREFERRED_COUNTRIES_BY_LANG = {
    en: ['US', 'GB', 'NZ', 'AU'],
    ru: ['UA', 'KZ', 'UZ'],
    ua: ['UA'],
    pl: ['PL'],
    mx: ['MX', 'ES', 'CO'],
};

export default PREFERRED_COUNTRIES_BY_LANG;
