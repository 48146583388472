export const COUNTRY_UKRAINE = 'UA';
export const COUNTRY_RUSSIA = 'RU';
export const COUNTRY_POLAND = 'PL';
export const COUNTRY_BELARUS = 'BY';
export const COUNTRY_KAZAKHSTAN = 'KZ';
export const COUNTRY_UZBEKISTAN = 'UZ';
export const COUNTRY_SOUTH_AFRICAN_REPUBLIC = 'ZA';
export const COUNTRY_MEXICO = 'MX';

// No BY and RU
export const ZONE_CIS = ['AZ', 'AM', 'KZ', 'MD', 'TJ', 'TM', 'UZ', 'KG'];

// Специальный язык для перевода текста прямо в интерфейсе через плагин lokalise
export const COUNTRY_NOWHERE = 'll';
