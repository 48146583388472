import l from './siteLanguage';

/**
 * Поменять видимость виджета Intercom
 * @param visibility
 */
export const changeIntercomWidgetVisibility = (visibility) => {
    if (window.Intercom) {
        window.Intercom('update', {
            hide_default_launcher: !visibility,
        });
    }
};

/**
 * Поменять видимость виджета Facebook
 * @param visibility
 */
export const changeFBMessengerWidgetVisibility = (visibility) => {
    if (window.FB && window.FB.CustomerChat) {
        if (visibility === true) {
            window.FB.CustomerChat.show();
        } else {
            window.FB.CustomerChat.hide();
        }
    }
};

/**
 * Поменять видимость виджета Binotel
 * @param visibility
 */
export const changeBinotelWidgetVisiblity = (visibility) => {
    const STYLE_TAG_ID = 'binotel-hide-style-tag';

    const display = visibility ? 'block' : 'none';
    let styleText = `#bingc-phone-button { display: ${display} !important; }`;

    if (!visibility) {
        styleText += ' #bingc-active { display: none !important; }';
    }

    let styleTag = document.getElementById(STYLE_TAG_ID);

    if (!styleTag) {
        styleTag = document.createElement('style');
        styleTag.id = STYLE_TAG_ID;
        document.head.appendChild(styleTag);
    }

    styleTag.innerHTML = styleText;
};

/**
 * Поменять видимость всех виджетов
 * @param visibility
 */
export const changeAllWidgetsVisiblity = (visibility) => {
    changeFBMessengerWidgetVisibility(visibility);
    changeIntercomWidgetVisibility(visibility);
    changeBinotelWidgetVisiblity(visibility);
};

/**
 * Проверяем, телефон или нет
 * @returns {boolean}
 */
export const isMobile = () => {
    const mobileRegexp = /Mobile/;
    return mobileRegexp.test(navigator.userAgent);
};

/**
 * Отправляем хит в Google Analytics и меняем урл
 * @param url
 */
export const virtualPageView = (url) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'virtualPageView',
        virtualPageViewPath: url,
    });

    window.history.pushState({}, '', url);
};

/**
 * Находимся на странице регистраци?
 * @param url
 * @returns {boolean}
 */
export const isSignupUrl = url => /^\/([a-z]{2}\/)?signup/.test(url);

/**
 * Подбираем множественное число для слов в зависимости от колличества
 * @param count
 * @param words
 * @returns {*}
 */
export const pluralForm = (count, words) => {
    const mod = count % 10;

    if (window.config.lang === 'ua' || window.config.lang === 'ru' || window.config.lang === 'pl') {
        if ((count >= 5) && (count <= 20)) {
            return words[2];
        }

        if (mod === 1) {
            return words[0];
        }

        if ((mod >= 2) && (mod <= 4)) {
            return words[1];
        }

        return words[2];
    }

    if (count > 1) {
        return words[1];
    }

    return words[0];
};

/**
 * Получаем ссылку на privacy policy для форм
 */
export const getPrivacyLink = () => {
    let privacyLink = 'https://joinposter.com/en/privacy';

    if (['RU', 'UA', 'AZ', 'AM', 'BY', 'KZ', 'MD', 'TJ', 'TM', 'UZ', 'KG'].includes(window.config.country)) {
        privacyLink = 'https://joinposter.com/privacy';
    } else if (window.config.country === 'MX') {
        privacyLink = 'https://joinposter.com/mx/privacy';
    }

    return privacyLink;
};

/**
 * Получаем текст лицензионного соглашения для формы с указанием текста кнопки submit
 * @param buttonText
 */
export const getTermsAndPrivacyText = (buttonText) => {
    const termsLink = `${window.config.langUrl}/terms`;
    const privacyLink = getPrivacyLink();

    return l('site.signupForm.step1PrivacyPolicyInline', buttonText, termsLink, privacyLink);
};

/**
 * Получаем список стран СНГ
 */
export const getCISCountries = () => (
    ['AZ', 'AM', 'BY', 'KZ', 'KG', 'MD', 'RU', 'TJ', 'UZ', 'TM', 'UA', 'GE', 'MN', 'AF']
);

/**
 * Проверяем страна из СНГ или нет
 * @param country
 * @returns {boolean}
 */
export const isCountryFromCIS = (country) => {
    const cisCountries = getCISCountries();

    return cisCountries.indexOf(country) !== -1;
};

export const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
};
