import {
    ENVIRONMENT_DEVELOPMENT,
    ENVIRONMENT_PRODUCTION,
    LOCAL_URI,
    MX_LOCAL_URI,
    STAGE_HOSTS_REGEX,
} from '../../consts/environment';

export function getEnvironment() {
    if ([LOCAL_URI, MX_LOCAL_URI].includes(window.location.host)) {
        return ENVIRONMENT_DEVELOPMENT;
    }
    return ENVIRONMENT_PRODUCTION;
}

export function isDev() {
    return getEnvironment() === ENVIRONMENT_DEVELOPMENT;
}

export function isStage() {
    return STAGE_HOSTS_REGEX.test(window.location.host);
}
