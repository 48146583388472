import { Component } from 'react';
import RemindUrlForm from './remindUrlForm';
import l from '../../helpers/siteLanguage';
import { PATTERN_VALID_SUBDOMAIN } from '../../../consts/patterns';
import { SIGN_IN_STEP_FORM, SIGN_IN_STEP_RECOVERY_FORM } from '../../../consts/site/signin';

class SigninForm extends Component {
    constructor(props) {
        super(props);

        this.formRef = null;
        this.urlInputRef = null;

        this.urlValidation = {
            required: {
                message: l('site.signinForm.subdomainRequired'),
            },
            pattern: {
                value: PATTERN_VALID_SUBDOMAIN,
                message: l('site.signinForm.subdomainContainsInvalidCharacters'),
            },
            callback: {
                isValid: async (url) => {
                    const res = await fetch('/api/site/isExistsUrl', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ url }),
                    });
                    const response = await res.json();

                    return response.success;
                },
                message: l('site.signinForm.UrlNotFound'),
            },
        };
    }

    state = {
        url: '',
        error: null,
        step: SIGN_IN_STEP_FORM,
    }

    componentDidMount() {
        this.urlInputRef?.focus();
        const myModalEl = document.getElementById('signInForm');
        if (myModalEl) {
            myModalEl.addEventListener('hide.bs.modal', () => {
                this.setState({
                    url: '',
                    error: null,
                    step: SIGN_IN_STEP_FORM,
                });
            });
        }
    }

    onShowRecoveryFormClick = () => {
        this.setState({ step: SIGN_IN_STEP_RECOVERY_FORM });
    }

    onBackToSignInClick = () => {
        this.setState({ step: SIGN_IN_STEP_FORM });
    }

    setFormRef = (ref) => {
        this.formRef = ref;
    }

    setUrlInputRef = (ref) => {
        this.urlInputRef = ref;
    }

    handleUrlChange = (e) => {
        const { value } = e.target;

        this.setState({ url: value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { valid, error } = await this.validate();
        if (valid) {
            e.target.submit();
        } else {
            this.setState({ error: error.value });
        }
    }

    validate = async () => {
        const { url } = this.state;
        let valid = true;
        let error = null;

        if (this.urlValidation?.required && !url) {
            valid = false;
            error = {
                type: 'invalid url',
                value: this.urlValidation.required.message,
            };
        }

        const pattern = this.urlValidation?.pattern;
        if (valid && pattern?.value && !RegExp(pattern.value).test(url)) {
            valid = false;
            error = {
                type: 'invalid symbols',
                value: pattern.message,
            };
        }

        const callback = this.urlValidation?.callback;
        if (valid && callback?.isValid && !await callback.isValid(url)) {
            valid = false;
            error = {
                type: 'invalid url',
                value: callback.message,
            };
        }

        return { valid, error };
    }

    render() {
        const { url, error, step } = this.state;
        return (
            <div className="modal fade" id="signInForm" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-6 p-mb-7">
                        <button
                            data-e2e-selector="button-close-modal"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="btn-close shadow-none position-absolute end-0 top-0 p-5"
                        />
                        <div className="signin-form-container">
                            <div>
                                { step === SIGN_IN_STEP_FORM ?
                                    <div className="signin-form" ref={this.setFormRef}>
                                        <h2>{l('site.signinForm.title')}</h2>
                                        <form action="/welcome/goto_url" method="POST" onSubmit={this.handleSubmit}>
                                            <label htmlFor="url">{l('site.signinForm.subdomainInputLabel')}</label>
                                            <div className="p-form-group subdomain-group mt-4">
                                                <div className="p-control-wrapper">
                                                    <input
                                                        data-e2e-selector="input-login-url"
                                                        type="text"
                                                        name="url"
                                                        id="url"
                                                        className="validate"
                                                        ref={this.setUrlInputRef}
                                                        value={url}
                                                        onChange={this.handleUrlChange}
                                                    />
                                                    {error && <div data-e2e-selector="text-login-error" className="error">{error}</div>}
                                                    <span className="subdomain-label" id="joinposter_com">.joinposter.com</span>
                                                </div>
                                            </div>
                                            <button
                                                data-e2e-selector="button-login"
                                                className="p-btn  btn shadow-none rounded-3 py-2 px-4 height-auto btn-primary h-auto "
                                            >
                                                {l('site.signinForm.submit')}
                                            </button>
                                            <br />
                                            <button
                                                data-e2e-selector="button-forget-url"
                                                className="btn btn-link text-decoration-none shadow-none p-0 fs-6 mt-6"
                                                onClick={this.onShowRecoveryFormClick}
                                            >
                                                {l('site.signinForm.forgotEmailLink')}
                                            </button>
                                        </form>
                                    </div>
                                    : <RemindUrlForm
                                        onBackToSignInClick={this.onBackToSignInClick}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SigninForm;
