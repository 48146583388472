import { isDev } from './environment';

function underLineToCamelCaseString(string) {
    let parts = String(string).split('_');

    parts = parts.map((part, index) => {
        if (index === 0) {
            return part;
        }
        return part[0].toUpperCase() + part.substring(1);
    });

    return parts.join('');
}

/**
 * Отправляет в аналитику ивент
 *
 * @param category {String} - название раздела, например, products, marketing
 * @param label {String} - тип функционала внутри раздела, например, sideMenu, listing, form
 * @param action {String} - название действия, например, click, scroll
 * @param event {String} - название event (manageMetrics, siteMetrics)
 */
export function sendAnalytics(category, label, action = 'click', event = 'manageMetrics') {
    if (isDev()) {
        return;
    }

    try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: underLineToCamelCaseString(event),
            eventCategory: underLineToCamelCaseString(category),
            eventLabel: underLineToCamelCaseString(label),
            eventAction: underLineToCamelCaseString(action),
        });
    } catch (e) {
        console.error('Data Layer Send error', e);
    }
}

/**
 * Получаем название раздела меню, для отправки в GA
 *
 * @returns {string | undefined}
 */
export function getCategoryNameForGA() {
    const pagesWithDates = ['stat'];
    const splittedUrl = window.location.pathname.split('/');
    const pageWithDatesInUrl = splittedUrl.find(element => pagesWithDates.includes(element));
    const categoryName = pageWithDatesInUrl || splittedUrl.pop();
    // Если это корзина, то отдаем название предыдущего раздела
    return categoryName === 'trash' ? splittedUrl.pop() : categoryName;
}
