export const BUSINESS_TYPE_COFFEESHOP = 'Кофейня';
export const BUSINESS_TYPE_FASTFOOD = 'Фаст-фуд';
export const BUSINESS_TYPE_STREETFOOD = 'Стрит-фуд';
export const BUSINESS_TYPE_BAKERY = 'Пекарня';
export const BUSINESS_TYPE_SWEETSHOP = 'Кондитерская';
export const BUSINESS_TYPE_CAFE = 'Кафе';
export const BUSINESS_TYPE_RESTAURANT = 'Ресторан';
export const BUSINESS_TYPE_PIZZA = 'Пиццерия';
export const BUSINESS_TYPE_BAR = 'Бар';
export const BUSINESS_TYPE_BEER = 'Пиво';
export const BUSINESS_TYPE_HOOKAH = 'Кальян';
export const BUSINESS_TYPE_DELIVERY = 'Доставка';
export const BUSINESS_TYPE_RETAIL = 'Магазин';
export const BUSINESS_TYPE_TAQUERIA = 'Такерия';
export const BUSINESS_TYPE_OTHER = 'Другое';

export const BUSINESS_TYPES = [
    { value: BUSINESS_TYPE_COFFEESHOP, label: 'Coffeeshop', defaultServiceModes: ['quickService', 'takeaway'] },
    { value: BUSINESS_TYPE_FASTFOOD, label: 'Fastfood', defaultServiceModes: ['quickService', 'takeaway', 'delivery'] },
    { value: BUSINESS_TYPE_STREETFOOD, label: 'Streetfood', defaultServiceModes: ['quickService', 'takeaway'] },
    { value: BUSINESS_TYPE_BAKERY, label: 'Bakery', defaultServiceModes: ['quickService', 'takeaway'] },
    { value: BUSINESS_TYPE_SWEETSHOP, label: 'Sweetshop', defaultServiceModes: ['quickService', 'takeaway'] },
    { value: BUSINESS_TYPE_CAFE, label: 'Cafe', defaultServiceModes: ['fullService', 'takeaway', 'delivery'] },
    { value: BUSINESS_TYPE_RESTAURANT, label: 'Restaurant', defaultServiceModes: ['fullService', 'takeaway', 'delivery'] },
    { value: BUSINESS_TYPE_PIZZA, label: 'Pizza', defaultServiceModes: ['fullService', 'takeaway', 'delivery'] },
    { value: BUSINESS_TYPE_BAR, label: 'Bar', defaultServiceModes: ['fullService'] },
    { value: BUSINESS_TYPE_BEER, label: 'Pub', defaultServiceModes: ['fullService', 'delivery'] },
    {
        value: BUSINESS_TYPE_HOOKAH,
        label: 'Hookah',
        defaultServiceModes: ['fullService'],
        countries: ['RU', 'UA', 'KZ', 'BY', 'LV', 'KG', 'UZ', 'CZ', 'TJ', 'EE', 'AZ'],
    },
    {
        value: BUSINESS_TYPE_TAQUERIA,
        label: 'Taqueria',
        defaultServiceModes: ['fullService', 'takeaway', 'delivery'],
        countries: ['MX'],
    },
    { value: BUSINESS_TYPE_DELIVERY, label: 'Delivery', defaultServiceModes: ['delivery'] },
    { value: BUSINESS_TYPE_RETAIL, label: 'Retail', defaultServiceModes: ['quickService'] },
    { value: BUSINESS_TYPE_OTHER, label: 'Other', defaultServiceModes: [] },
];
