import { load } from 'recaptcha-v3';

export default async (token, actionName) => {
    if (window.config.recaptchaKey) {
        const recaptcha = await load(window.config.recaptchaKey, {
            useRecaptchaNet: true,
            autoHideBadge: true,
        });

        return recaptcha.execute(actionName);
    }

    return null;
};
