export const NOT_ACTUAL_TASK_STATUS = '4';

export const HARDWARE_TICKET_TYPE_VALUE_FIRST_SET_UP = 'First configuration';
export const HARDWARE_TICKET_TYPE_VALUE_ISSUE = 'Already configured';
export const HARDWARE_TICKET_TYPE_VALUE_QUESTION = 'Question & consultation';
export const HARDWARE_TICKET_TYPE_VALUE_ADVANCED = 'Advanced configuration';

export const HARDWARE_TICKET_CATEGORY_VALUE_PRINTER = 'Receipt printers';
export const HARDWARE_TICKET_CATEGORY_VALUE_FISCAL_PRINTER = 'Fiscal printers';
export const HARDWARE_TICKET_CATEGORY_VALUE_TERMINAL = 'Payment terminals';
export const HARDWARE_TICKET_CATEGORY_VALUE_SCALE = 'Scales';
export const HARDWARE_TICKET_CATEGORY_VALUE_NETWORK = 'Network';
export const HARDWARE_TICKET_CATEGORY_VALUE_KK = 'Kitchen Kit';

export const CASE_QUESTION_API = 'api';
export const CASE_QUESTION_OTHER = 'other';

export const CASE_TICKET_TYPE_SETUP = 'Setup';
export const CASE_TICKET_TYPE_DIFF = 'Difference';
export const CASE_TICKET_TYPE_CALCULATION = 'Check calculation';
export const CASE_TICKET_TYPE_QUESTION = 'Question';
export const CASE_TICKET_TYPE_OTHER = 'Other case';

export const HARDWARE_TICKET_CATEGORY_MAPPING = [
    { label: HARDWARE_TICKET_CATEGORY_VALUE_PRINTER, prop: 'printer' },
    { label: HARDWARE_TICKET_CATEGORY_VALUE_FISCAL_PRINTER, prop: 'fiscalPrinter' },
    { label: HARDWARE_TICKET_CATEGORY_VALUE_TERMINAL, prop: 'payTerminal' },
    { label: HARDWARE_TICKET_CATEGORY_VALUE_SCALE, prop: 'scales' },
];

export const HARDWARE_TICKET_CONNECTIONS = [
    { label: 'Ethernet', value: 'Ethernet' },
    { label: 'USB', value: 'USB' },
    { label: 'Wi-Fi', value: 'Wi-Fi' },
    { label: 'Bluetooth', value: 'Bluetooth' },
    { label: 'Другое: Lightning, RJ-12, переходник', value: 'Другое: Lightning, RJ-12, переходник' },
];

export const SETUP_TYPES = [
    { label: '1C', value: '1C' },
    { label: 'ПРРО', value: 'ПРРО' },
    { label: 'crm.client.ticketFormSetUpTypeOther', value: 'Другое (написать что именно)' },
];

export const CRM_HISTORY_TYPE_TRANSLATION_SLUG_MAPPING = {
    comment: 'crm.client.groupedItemCrmHistoryCommentSelectedLabel',
    task: 'crm.client.groupedItemCrmHistoryTaskSelectedLabel',
    call: 'crm.client.groupedItemCrmHistoryCallSelectedLabel',
    chat: 'crm.client.groupedItemCrmHistoryChatsSelectedLabel',
    subscription: 'crm.client.groupedItemCrmHistorySubscriptionSelectedLabel',
    common: 'crm.client.groupedItemCrmHistoryCommonSelectedLabel',
};

export const CLIENT_STATUS_NEW = 0;
export const CLIENT_STATUS__TESTING_HOT = 1;
export const CLIENT_STATUS_PAYED = 2;
export const CLIENT_STATUS_BLOCKED = 6;
export const CLIENT_STATUS_DELETED = 7;
export const CLIENT_STATUS_TESTING_COLD = 8;
export const CLIENT_STATUS_DUPLICATE = 9;
export const CLIENT_STATUS_FREE = 10;
export const CLIENT_STATUS_REJECTED_PAYED = 11;
export const CLIENT_STATUS_EMPLOYEE = 12;
export const CLIENT_STATUS_PARTNER = 13;
export const CLIENT_STATUS_SEASON = 14;
export const CLIENT_STATUS_TEMPORARY_NOT_WORKING = 15;
export const CLIENT_STATUS_OPEN_SOON = 16;
export const CLIENT_STATUS_REFUSAL_WORK = 19;
export const CLIENT_STATUS_DEVELOPER = 20;
export const CLIENT_STATUS_REGISTERED = 23;

export const CLIENT_PARAMETERS_TYPE_FREEZING_SUBSCRIPTION = 'freezing_params';
export const CLIENT_PARAMETERS_STATUS_TYPE = 'statusType';

export const TASK_EXECUTOR_SINGLE = 'single';
export const TASK_EXECUTOR_PERSONAL_MANAGER = 'personal_manager';

export const SYSTEM_STATUS_ACTIVE = 0;
export const SYSTEM_STATUS_NEED_TO_ARCHIVE = 1;
export const SYSTEM_STATUS_ARCHIVED = 2;
export const SYSTEM_STATUS_NEED_UNARCHIVE = 3;
export const SYSTEM_STATUS_UNARCHIVING = 4;

export const CONTACT_TO_CLIENT_ACTION_CALL = 'callAction';
export const CONTACT_TO_CLIENT_ACTION_SMS = 'smsAction';
export const CONTACT_TO_CLIENT_ACTION_WHATS_APP = 'whatsAppAction';

export const CALL_DRIVER_BINOTEL = 'binotel';
export const CALL_DRIVER_RINGOSTAT = 'ringostat';

export const TAG_NAME_PAY_FOR_MASTER = 'Pay for master';

export const USER_ID_FAKE_STEPHAN = 67;
export const USER_ID_POZZHE_FEDOR = 68;
export const USER_ID_RUBEN = 312;
export const USER_ID_URIEL = 748;
export const USER_ID_RODRIGO = 672;
export const USER_ID_THE_RING = 843;

export const FRANCHISE_STATUS_WITHOUT = 0;
export const FRANCHISE_STATUS_FRANCHISE_OWNER = 1;
export const FRANCHISE_STATUS_FRANCHISEE = 2;
export const FRANCHISE_STATUS_MASTER = 3;

export const PAYMENT_METHOD_WITHOUT_PAYMENT = 1;

export const TEAM_ID_GRYPHONS = 59;

export const MAP_BOX_ACCESS_TOKEN = 'pk.eyJ1IjoicG9zdGVycG9zIiwiYSI6ImNsMjRlZW1udjAzaHIzZnA2OGNpcXc4dnYifQ.YlXEx5DbYjIBB3XRnrA0Tw';

export const COHORT_TOP = 'top';

export const COHORT_MAIN = 'main';

export const COHORT_LOW = 'low';

export const COHORT_CHAT = 'chat';

export const BIG_BUSINESS_TAGS = [
    'Big_business',
    '⌘ Franchising',
    'valuable_business_influencer',
    'Duplicate_BB',
];
