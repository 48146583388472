/* eslint-disable jsx-a11y/alt-text */

import PropTypes from 'prop-types';
import { COUNTRY_UKRAINE } from '../../consts/countries';

const DEFAULT_CURRENCIES_BY_LANG = {
    ua: 'uah', en: 'usd', pl: 'pln', mx: 'mxn', ru: 'rub',
};
const LANG_FALLBACK = { mx: 'en', ua: 'ru', pl: 'en' };

/**
 * Рендерит картинку на нужном языке и с нужной валютой
 */
export default function LocalizedImage(props) {
    const {
        availableSlugs, src, language, country, doubleSize, ...other
    } = props;

    const fileNameParts = src.split('.');
    const extension = fileNameParts[fileNameParts.length - 1];
    const fileNameWithoutExtension = fileNameParts.slice(0, -1).join('.');

    let currency = DEFAULT_CURRENCIES_BY_LANG[language];
    if (language === 'ru' && country === COUNTRY_UKRAINE) {
        currency = 'uah';
    }
    let slug = `${language}-${currency}`;

    if (availableSlugs.indexOf(slug) === -1) {
        const fallbackLang = LANG_FALLBACK[language];
        slug = `${fallbackLang}-${DEFAULT_CURRENCIES_BY_LANG[fallbackLang]}`;
    }

    return (
        <img
            src={`${fileNameWithoutExtension}-${slug}.${extension}`}
            srcSet={doubleSize ? `${fileNameWithoutExtension}-${slug}@2x.${extension} 2x` : ''}
            {...other}
        />
    );
}

LocalizedImage.propTypes = {
    availableSlugs: PropTypes.arrayOf(PropTypes.string).isRequired,
    src: PropTypes.string.isRequired,
    language: PropTypes.string,
    country: PropTypes.string,
    doubleSize: PropTypes.bool,
};

LocalizedImage.defaultProps = {
    language: 'en',
    country: 'US',
    doubleSize: true,
};
