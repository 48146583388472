import { Component, createRef } from 'react';
import PosterUiKit from 'poster-ui-kit';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BUSINESS_TYPES, BUSINESS_TYPE_OTHER, BUSINESS_TYPE_DELIVERY, BUSINESS_TYPE_RETAIL } from '../../../consts/siteBusinessTypes';
import { BUSINESS_STATUS_NO, BUSINESS_STATUS_OPENS_SOON, BUSINESS_STATUS_YES } from '../../../consts/siteBusinessStatuses';
import { isMobile, virtualPageView } from '../../helpers/siteHelper';
import l from '../../helpers/siteLanguage';

export default class StepBusinessInfo extends Component {
    propTypes = {
        setBusinessInfoData: PropTypes.func.isRequired,
        askPassword: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.nameInputRef = createRef();
        this.promoCodeRef = createRef();
    }

    state = {
        inProgress: false,
        formValid: false,
        firstSubmitAttemptDone: false,

        name: { value: '', valid: false, showError: false },
        password: { value: '', valid: false, showError: false },
        businessStatus: { value: '0', showError: false },
        businessType: { value: '0', showError: false },
        employeesNumber: { value: '0', showError: false },
        serviceModeFullService: true,
        serviceModeQuickService: true,
        serviceModeDelivery: true,
        serviceModeTakeaway: true,
        promoCodeVisible: false,
        promoCode: { value: '' },
    }

    componentDidMount() {
        // На десктопе фокусим первый инпут
        if (!isMobile()) {
            this.nameInputRef.current.focus();
        }

        // Отправляем хит в GA и меняем урл
        virtualPageView(`${window.config.langUrl}/signup/step2`);
    }

    /**
     * Обработчик изменения селекта
     * @param selectName
     * @param e
     */
    onSelectChange = (selectName, e) => {
        const state = {
            [selectName]: _.extend({}, this.state[selectName], {
                value: e.target.value,
            }),
        };

        if (selectName === 'businessType') {
            // Проставляем режимы обслуживания, которые соответствуют выбранному типу бизнеса
            const businessType = _.findWhere(BUSINESS_TYPES, { value: e.target.value });

            state.serviceModeFullService = businessType.defaultServiceModes.indexOf('fullService') > -1;
            state.serviceModeQuickService = businessType.defaultServiceModes.indexOf('quickService') > -1;
            state.serviceModeDelivery = businessType.defaultServiceModes.indexOf('delivery') > -1;
            state.serviceModeTakeaway = businessType.defaultServiceModes.indexOf('takeaway') > -1;
        }

        this.setState(state, () => {
            this.calculateFormValid();
        });
    };

    /**
     * Обработчик изменения тоггла
     * @param checkboxName
     * @param e
     */
    onCheckboxChange = (checkboxName, e) => {
        this.setState({
            [checkboxName]: e.target.checked,
        }, () => {
            this.calculateFormValid();
        });
    };

    /**
     * Обработчик изменения инпута
     * @param inputName
     * @param e
     */
    onInputChange = (inputName, e) => {
        const { value } = e.target;
        let valid;

        if (inputName === 'name') {
            valid = value.toString().length >= 2;
        }

        if (inputName === 'password') {
            valid = value.toString().length >= 6;
        }

        if (inputName === 'promoCode') {
            valid = true;
        }

        this.setState({
            [inputName]: _.extend({}, this.state[inputName], {
                value,
                valid,
            }),
        }, () => {
            this.calculateFormValid();
        });
    }

    /**
     * Обработчки блура инпута
     * @param inputName
     */
    onInputBlur = (inputName) => {
        const inputState = this.state[inputName];

        // После того, как в инпут что-то ввели в первый раз и заблурили,
        // разрешаем показывать его ошибки валидации
        if (inputState.value.toString() !== '') {
            this.setState({
                [inputName]: _.extend({}, inputState, {
                    showError: true,
                }),
            });
        }
    }

    /**
     * Обработчик сабмита формы
     * @param e
     */
    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            firstSubmitAttemptDone: true,
        });

        if (this.state.formValid) {
            this.setState({
                inProgress: true,
            });

            const {
                businessStatus,
                employeesNumber,
                serviceModeFullService,
                serviceModeQuickService,
                serviceModeDelivery,
                serviceModeTakeaway,
                name,
                password,
                promoCode,
            } = this.state;

            let businessType = this.state.businessType.value;

            if (businessType === BUSINESS_TYPE_OTHER) {
                businessType = '';
            }
            // Закомментировал простановку типа бизнеса «Доставка» через запятую для всех кто доставляет заказы
            // т.к. это создавало много непонятнок в отчётности
            /* else if (serviceModeDelivery && businessType !== BUSINESS_TYPE_DELIVERY && businessStatus.value !== BUSINESS_STATUS_NO) {
                businessType = `${businessType},${BUSINESS_TYPE_DELIVERY}`;
            }*/

            let companyType = businessType === BUSINESS_TYPE_RETAIL ? 2 : 1;

            if (businessType === '0' && this.props.urlCompanyType) {
                companyType = this.props.urlCompanyType;
            }

            this.props.setBusinessInfoData({
                ownerName: name.value,
                password: password.value,
                promoCode: promoCode.value,
                companyType,
                businessType,
                businessStatus: businessStatus.value,
                employeesNumber: employeesNumber.value,
                serviceModeFullService,
                serviceModeQuickService,
                serviceModeDelivery,
                serviceModeTakeaway,
            });
        }
    };

    /**
     * Показываем вопросы про формат, режимы обслуживания и размер бизнеса, если бизнес в принципе есть
     * @returns {boolean}
     */
    businessDetailsVisible = () => [BUSINESS_STATUS_OPENS_SOON, BUSINESS_STATUS_YES].indexOf(this.state.businessStatus.value) > -1;

    /**
     * Проверяем валидность формы
     */
    calculateFormValid = () => {
        const { askPassword } = this.props;
        const {
            name, businessStatus, password, businessType, employeesNumber,
        } = this.state;

        let valid = name.valid && businessStatus.value !== '0';

        if (this.businessDetailsVisible()) {
            valid = valid && businessType.value !== '0' && employeesNumber.value !== '0';
        }

        if (askPassword) {
            valid = valid && password.valid;
        }

        this.setState({
            formValid: valid,
        });
    };

    /**
     * Возвращаем текст ошибки валидации
     * @param field
     */
    validationError = (field) => {
        const camelCaseField = field.charAt(0).toUpperCase() + field.slice(1);
        const emptyLangKey = `site.signupForm.validationError${camelCaseField}Empty`;
        const incorrectLangKey = `site.signupForm.validationError${camelCaseField}Incorrect`;

        if (this.state[field].value.toString().length === 0) {
            return l(emptyLangKey);
        }

        // Если есть расширенная валидация у этого поля, возвращаем ее
        if (l(incorrectLangKey) !== incorrectLangKey) {
            return l(incorrectLangKey);
        }

        return l(emptyLangKey);
    }

    showPromoCodeInput = (e) => {
        e.preventDefault();

        this.setState({ promoCodeVisible: true }, () => {
            this.promoCodeRef.current.focus();
        });
    };

    render() {
        const { askPassword } = this.props;
        const {
            firstSubmitAttemptDone, name, inProgress, password, businessStatus, businessType, serviceModeQuickService,
            serviceModeFullService, serviceModeTakeaway, serviceModeDelivery, employeesNumber, formValid,
            promoCodeVisible, promoCode,
        } = this.state;

        const passwordErrorVisible = (firstSubmitAttemptDone || password.showError) && !password.valid;
        const businessTypes = BUSINESS_TYPES.filter(type => !type.countries ||
            (type.countries && type.countries.indexOf(window.config.country) > -1));

        return (
            <div className="step-business-info">
                <form
                    action=""
                    onSubmit={this.onSubmit}
                >
                    <PosterUiKit.BlockTitle
                        title={l('site.signupForm.header')}
                        hint={`${l('site.signupForm.stepXofN', { step: 2, total: 2 })}: ${l('site.signupForm.stepSetupAccount')}`}
                    />

                    <PosterUiKit.FormGroup
                        label={l('site.signupForm.yourName')}
                        id="input-name"
                        error={(firstSubmitAttemptDone || name.showError) && !name.valid ? this.validationError('name') : ''}
                        vertical
                    >
                        <input
                            type="text"
                            id="input-name"
                            value={name.value}
                            onInput={this.onInputChange.bind(this, 'name')}
                            onBlur={this.onInputBlur.bind(this, 'name')}
                            disabled={inProgress}
                            ref={this.nameInputRef}
                        />

                        { name.valid && (
                            <div className="valid-checkmark" />
                        )}
                    </PosterUiKit.FormGroup>

                    {askPassword && (
                        <PosterUiKit.FormGroup
                            label={l('site.signupForm.password')}
                            id="input-password"
                            vertical
                            error={passwordErrorVisible ? this.validationError('password') : ''}
                        >
                            <input
                                type="password"
                                id="input-password"
                                value={password.value}
                                onInput={this.onInputChange.bind(this, 'password')}
                                onBlur={this.onInputBlur.bind(this, 'password')}
                                disabled={inProgress}
                            />

                            { !passwordErrorVisible && !password.valid && (
                                <div className="hint">
                                    {l('site.signupForm.passwordPlaceholder')}
                                </div>
                            )}

                            { password.valid && (
                                <div className="valid-checkmark" />
                            )}
                        </PosterUiKit.FormGroup>
                    )}

                    <PosterUiKit.FormGroup
                        label={l('site.signupForm.businessStatus')}
                        id="select-business-status"
                        vertical
                        error={(firstSubmitAttemptDone || businessStatus.showError) && businessStatus.value === '0' ?
                            this.validationError('businessStatus') : ''}
                    >
                        <select
                            id="select-business-status"
                            value={businessStatus.value}
                            onChange={this.onSelectChange.bind(this, 'businessStatus')}
                            onBlur={this.onInputBlur.bind(this, 'businessStatus')}
                            className={classNames({ placeholder: businessStatus.value === '0' })}
                            disabled={inProgress}
                        >
                            <option value="0" disabled>{l('site.signupForm.choose')}</option>
                            <option value={BUSINESS_STATUS_NO}>{l('site.signupForm.businessStatusNo')}</option>
                            <option value={BUSINESS_STATUS_OPENS_SOON}>{l('site.signupForm.businessStatusOpenSoon')}</option>
                            <option value={BUSINESS_STATUS_YES}>{l('site.signupForm.businessStatusYes')}</option>
                        </select>

                        { businessStatus.value !== '0' && <div className="valid-checkmark" /> }
                    </PosterUiKit.FormGroup>

                    { this.businessDetailsVisible() && (
                        <div>
                            <PosterUiKit.FormGroup
                                label={l('site.signupForm.businessType')}
                                id="select-business-type"
                                vertical
                                error={(firstSubmitAttemptDone || businessType.showError) && businessType.value === '0' ?
                                    this.validationError('businessType') : ''}
                            >
                                <select
                                    id="select-business-type"
                                    value={businessType.value}
                                    onChange={this.onSelectChange.bind(this, 'businessType')}
                                    onBlur={this.onInputBlur.bind(this, 'businessType')}
                                    className={classNames({ placeholder: businessType.value === '0' })}
                                    disabled={inProgress}
                                >
                                    <option value="0" disabled>{l('site.signupForm.choose')}</option>

                                    { businessTypes.map(type => (
                                        <option value={type.value} key={type.value}>
                                            {l(`site.signupForm.businessType${type.label}`)}
                                        </option>
                                    ))}
                                </select>

                                { businessType.value !== '0' && <div className="valid-checkmark" /> }
                            </PosterUiKit.FormGroup>

                            <div className="p-form-group vertical">
                                <div className="p-control-label">{l('site.signupForm.serviceModes')}</div>

                                <div className="row negative-margin">
                                    <div className="col-sm-6 col-xs-12">
                                        <label className="label-toggle">
                                            <PosterUiKit.Toggle
                                                checked={serviceModeQuickService}
                                                onChange={this.onCheckboxChange.bind(this, 'serviceModeQuickService')}
                                                className="ib "
                                                disabled={inProgress}
                                            /> {l('site.signupForm.serviceModeQuickService')}
                                        </label>

                                        { businessType.value !== BUSINESS_TYPE_RETAIL && (
                                            <label className="label-toggle">
                                                <PosterUiKit.Toggle
                                                    checked={serviceModeFullService}
                                                    onChange={this.onCheckboxChange.bind(this, 'serviceModeFullService')}
                                                    className="ib "
                                                    disabled={inProgress}
                                                /> {l('site.signupForm.serviceModeFullService')}
                                            </label>
                                        )}
                                    </div>

                                    <div className="col-sm-6 col-xs-12">
                                        { businessType.value !== BUSINESS_TYPE_RETAIL && (
                                            <label className="label-toggle">
                                                <PosterUiKit.Toggle
                                                    checked={serviceModeTakeaway}
                                                    onChange={this.onCheckboxChange.bind(this, 'serviceModeTakeaway')}
                                                    className="ib "
                                                    disabled={inProgress}
                                                /> {l('site.signupForm.serviceModeTakeaway')}
                                            </label>
                                        )}

                                        <label className="label-toggle">
                                            <PosterUiKit.Toggle
                                                checked={serviceModeDelivery}
                                                onChange={this.onCheckboxChange.bind(this, 'serviceModeDelivery')}
                                                className="ib "
                                                disabled={inProgress}
                                            /> {l('site.signupForm.serviceModeDelivery')}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <PosterUiKit.FormGroup
                                label={l('site.signupForm.employeesNumber')}
                                id="select-employees-number"
                                vertical
                                error={(firstSubmitAttemptDone || employeesNumber.showError) && employeesNumber.value === '0' ?
                                    this.validationError('employeesNumber') : ''}
                            >
                                <select
                                    id="select-employees-number"
                                    value={employeesNumber.value}
                                    onChange={this.onSelectChange.bind(this, 'employeesNumber')}
                                    onBlur={this.onInputBlur.bind(this, 'employeesNumber')}
                                    className={classNames({ placeholder: employeesNumber.value === '0' })}
                                    disabled={inProgress}
                                >
                                    <option value="0" disabled>{l('site.signupForm.choose')}</option>

                                    {[
                                        { value: '1', label: '1-3' },
                                        { value: '4', label: '4–9' },
                                        { value: '10', label: '10–19' },
                                        { value: '20', label: l('site.signupForm.employeesNumberXAndMore', { number: 20 }) },
                                    ].map(item => (
                                        <option value={item.value} key={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>

                                { employeesNumber.value !== '0' && <div className="valid-checkmark" /> }
                            </PosterUiKit.FormGroup>
                        </div>
                    )}

                    { promoCodeVisible ? (
                        <PosterUiKit.FormGroup
                            label={l('site.signupForm.promoCode')}
                            id="input-promocode"
                            vertical
                        >
                            <input
                                type="text"
                                id="input-promocode"
                                value={promoCode.value}
                                onInput={this.onInputChange.bind(this, 'promoCode')}
                                onBlur={this.onInputBlur.bind(this, 'promoCode')}
                                disabled={inProgress}
                                ref={this.promoCodeRef}
                            />

                            { promoCode.value.trim().length > 0 && (
                                <div className="valid-checkmark" />
                            )}
                        </PosterUiKit.FormGroup>
                    ) : (
                        <div className="i-have-promocode">
                            <a
                                href="#"
                                onClick={this.showPromoCodeInput}
                            >
                                {l('site.signupForm.iHavePromoCode')}
                            </a>
                        </div>
                    )}

                    <PosterUiKit.Button
                        className="ib m-r-15 big blue"
                        inputBlocked={!formValid || inProgress}
                        inProgress={inProgress}
                    >
                        {l('site.signupForm.openAccount')}
                    </PosterUiKit.Button>
                </form>
            </div>
        );
    }
}
