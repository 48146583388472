import { Component } from 'react';
import PosterUiKit from 'poster-ui-kit';
import { virtualPageView } from '../../helpers/siteHelper';
import l from '../../helpers/siteLanguage';

export default class StepPreloader extends Component {
    componentDidMount() {
        // Отправляем хит в GA и меняем урл
        virtualPageView(`${window.config.langUrl}/signup/finish`);
    }

    render() {
        return (
            <div className="step-preloader">
                <div className="preloader-wrapper">
                    <PosterUiKit.Spinner
                        style={{ width: 35, height: 35 }}
                    />
                    <h3>{l('site.signupForm.settingUpAccount')}</h3>
                    <p>{l('site.signupForm.takesUpTo10Seconds')}</p>
                </div>
            </div>
        );
    }
}
