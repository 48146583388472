import { Component, createRef } from 'react';
import PosterUiKit from 'poster-ui-kit';
import IntlTelInput from 'react-intl-tel-input';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import Cookies from 'js-cookie';
import { loadScript } from '../../../pos/login/helper';
import PREFERRED_COUNTRIES_BY_LANG from '../../../consts/sitePreferredCountriesByLang';
import {isMobile, getPrivacyLink, getTermsAndPrivacyText} from '../../helpers/siteHelper';
import StepAccountInput from './stepAccountInput';
import l from '../../helpers/siteLanguage';

export default class StepAccount extends Component {
    propTypes = {
        createAccount: PropTypes.func.isRequired,
        inlineForm: PropTypes.bool,
        inlineFormActive: PropTypes.bool,
        submitButtonText: PropTypes.string,
    };

    defaultProps = {
        inlineForm: false,
        inlineFormActive: false,
    };

    constructor(props) {
        super(props);

        this.emailInputRef = createRef();

        this.recaptchaRef = React.createRef();

        this.state = {
            firstSubmitAttemptDone: false,
            formValid: false,
            inProgress: false,
            urlRequestInProgress: false,
            phoneRequestInProgress: false,
            phoneUtilsLoaded: false,

            businessName: { valid: false, value: '', showError: false },
            email: { valid: false, value: '', showError: false },
            name: { valid: false, value: '', showError: false },
            password: { valid: false, value: '', showError: false },
            phone: {
                valid: false,
                value: '',
                fullNumber: '',
                country: window.config.country,
                showError: false,
            },

            accountUrl: '',
            acceptPrivacyPolicy: 0,
        };
    }

    componentDidMount() {
        // Фокусим первый инпут в форме на десктопе в полной форме регистрации
        if (!isMobile() && !this.props.inlineForm) {
            this.emailInputRef.current.focus();
        }
    }

    /**
     * Обработчик изменения инпута
     * @param inputName
     * @param e
     */
    onInputChange = (inputName, e) => {
        const { value } = e.target;
        let valid;

        switch (inputName) {
            case 'businessName': {
                valid = value.toString().length >= 2;

                if (!valid) {
                    this.setState({
                        accountUrl: '',
                        urlRequestInProgress: false,
                    });
                } else {
                    this.setState({
                        urlRequestInProgress: true,
                    });

                    this.onBusinessNameChange();
                }

                break;
            }

            case 'email': {
                const emailRegexp = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
                    '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$', 'g');

                valid = emailRegexp.test(String(value).toLowerCase());
                break;
            }

            case 'password': {
                valid = value.toString().length >= 6;
                break;
            }

            case 'name': {
                valid = value.toString().length >= 2;
                break;
            }

            default:
                break;
        }

        this.setState({
            [inputName]: _.extend({}, this.state[inputName], {
                value,
                valid,
            }),
        }, () => {
            this.calculateFormValid();
        });
    }

    /**
     * Отдельный обработчик изменения номера телефона через intlPhoneNumber
     * @param valid
     * @param phone
     * @param country
     */
    onPhoneChange = (valid, phone, country) => {
        // посылаем запрос на бек для валидации телефона
        this.setState({
            phoneRequestInProgress: true,
            phone: _.extend({}, this.state.phone, {
                value: phone,
                country: country.iso2,
            }),
        });


        // формат выдаваемый фронтовой либой не всегда принимается на бекенде, оставляем старое преображение телефона
        if (window.intlTelInputUtils) {
            phone = window.intlTelInputUtils.formatNumber(
                '+'.concat(country.dialCode).concat(phone),
                country.iso2,
                window.intlTelInputUtils.numberFormat.E164,
            );
        }

        if (this.requestValidatePhone) {
            this.requestValidatePhone.abort();
        }

        this.requestValidatePhone = $.post(`${window.config.langUrl}/signup/validatePhone`, {
            phone,
        }, (answer) => {
            this.setState({
                phone: _.extend({}, this.state.phone, {
                    valid: answer.valid,
                    fullNumber: answer.fullNumber ? answer.fullNumber : '',
                }),
                phoneRequestInProgress: false,
            }, () => {
                this.calculateFormValid();
            });
        });
    };

    /**
     * Обработчик сабмита формы
     * @param e
     */
    onSubmit = async (e) => {
        e.preventDefault();

        const { createAccount, inlineForm } = this.props;
        const {
            formValid, urlRequestInProgress, phone, businessName, name, email, password, accountUrl, acceptPrivacyPolicy,
            phoneUtilsLoaded, phoneRequestInProgress,
        } = this.state;

        this.setState({
            firstSubmitAttemptDone: true,
        });

        if (formValid && !urlRequestInProgress && !phoneRequestInProgress) {
            this.setState({
                inProgress: true,
            });

            // reCaptcha token
            const token = window.config.recaptchaKey ? await this.recaptchaRef.current.executeAsync() : '';

            setTimeout(() => {
                createAccount({
                    phone: phone.fullNumber,
                    phoneRaw: phone.value,
                    phoneUtilsLoaded,
                    country: phone.country.toUpperCase(),
                    businessName: businessName.value,
                    email: email.value,
                    ownerName: name.value,
                    password: password.value,
                    url: accountUrl,
                    acceptPrivacyPolicy,
                    token,
                    inlineForm,
                });
            }, 500);
        }
    };

    /**
     * Запрашиваем транслитерацию урла с бекенда при изменении названия заведения
     */
    onBusinessNameChange = _.debounce(() => {
        if (this.requestUrlFromBusinessName) {
            this.requestUrlFromBusinessName.abort();
        }

        this.requestUrlFromBusinessName = $.post(`${window.config.langUrl}/signup/getUrlFromBusinessName`, {
            businessName: this.state.businessName.value,
        }, (answer) => {
            const state = {
                urlRequestInProgress: false,
            };

            if (this.state.businessName.valid) {
                state.accountUrl = answer;
            }

            this.setState(state, () => {
                this.calculateFormValid();
            });
        });
    }, 250);

    /**
     * Обработчки блура инпута
     * @param inputName
     */
    onInputBlur = (inputName) => {
        const inputState = this.state[inputName];

        // После того, как в инпут что-то ввели в первый раз и заблурили,
        // разрешаем показывать его ошибки валидации
        if (inputState.value.toString() !== '' || inputState.showError) {
            this.setState({
                [inputName]: _.extend({}, inputState, {
                    showError: true,
                }),
            });
        }
    }

    /**
     * Обработчки фокуса инпута
     * @param inputName
     */
    onInputFocus = (inputName) => {
        this.loadUtils();
    }

    /**
     * Обработчки нажатия на чекбокс privacy_policy
     */
    handleCheckClick = (event) => {
        this.setState({
            acceptPrivacyPolicy: event.target.checked ? 1 : 0,
        });
    };

    /**
     * Обрабатываем фокус формы для inlineForm. показываем чекбоксы с privacy_policy если есть
     */
    handleFormFocus = () => {
        this.loadUtils();

        this.setState({ inlineFormActive: true });
    };

    /**
     * Сохраняем данные из формы по мере ввода. Если посетитель уйдет с сайта,
     * не закончив регистрацию, догоним его письмом
     */
    saveSignupData = _.debounce(() => {
        const {
            businessName,
            accountUrl,
            email,
            phone,
        } = this.state;

        $.post(`${window.config.langUrl}/signup/save`, {
            lang: window.config.lang,
            place_name: businessName.value,
            url: accountUrl,
            email: email.value,
            phone: phone.value,
        });
    }, 1000);

    /**
     * Определяем, валидная ли форма
     */
    calculateFormValid = () => {
        const {
            businessName, phone, email, password,
        } = this.state;
        const { inlineForm } = this.props;

        this.setState({
            formValid: businessName.valid &&
                phone.valid &&
                email.valid &&
                (inlineForm || password.valid), // в инлайновой форме не выводим поле для ввода пароля
        });

        this.saveSignupData();
    };

    /**
     * Считаем, сколько цифр должно быть в номере телефона для текущей выбранной страны
     * @returns {boolean|number}
     */
    phoneNumberLength = () => {
        if (window.intlTelInputUtils) {
            return window.intlTelInputUtils.getExampleNumber(
                this.state.phone.country,
                false,
                window.intlTelInputUtils.numberType.MOBILE,
            ).replace(/[^0-9]/g, '').length;
        }

        return false;
    }

    /**
     * Возвращаем текст ошибки валидации
     * @param field
     */
    validationError = (field) => {
        const camelCaseField = field.charAt(0).toUpperCase() + field.slice(1);
        const emptyLangKey = `site.signupForm.validationError${camelCaseField}Empty`;
        const incorrectLangKey = `site.signupForm.validationError${camelCaseField}Incorrect`;

        if (this.state[field].value.toString().length === 0) {
            return l(emptyLangKey);
        }

        // Если есть расширенная валидация у этого поля, возвращаем ее
        if (l(incorrectLangKey) !== incorrectLangKey) {
            return l(incorrectLangKey);
        }

        return l(emptyLangKey);
    }

    /**
     * Возвращаем текст ошибки валидации телефона
     */
    phoneValidationError = () => {
        const phoneNumberLength = this.phoneNumberLength();
        const { phone } = this.state;

        if (phone.value.toString().length === 0) {
            return l('site.signupForm.validationErrorPhoneEmpty');
        }

        // Если знаем, сколько цифр должно быть в номере телефона этой страны
        if (phoneNumberLength !== false && phone.fullNumber.replace(/[^0-9]/g, '').length !== phoneNumberLength) {
            return l('site.signupForm.validationErrorPhoneIncorrectDigitsCount', { number: phoneNumberLength });
        }

        return l('site.signupForm.validationErrorPhoneIncorrect');
    }

    /**
     * Подгружаем utils для телефонного инпута
     */
    loadUtils = () => {
        if (!this.state.phoneUtilsLoaded) {
            // Грузим библиотеку для форматирования и валидации номера телефона
            loadScript('/js/site/libs/intl-tel-input-utils-17-0-13.js', () => {
                this.setState({
                    phoneUtilsLoaded: true,
                });
            });
        }
    }

    render() {
        let preferredCountries = PREFERRED_COUNTRIES_BY_LANG[window.config.lang] || [];
        preferredCountries = _.without(preferredCountries, window.config.country);
        preferredCountries.unshift(window.config.country);
        let formSubmitText = l('site.signupForm.buttonProceed');

        const { inlineForm, isTallyAvailable } = this.props; // как вызывается форма — инлайново на странице или в составе попапа

        const { submitButtonText } = this.props; // другое название у кнопки самбита

        const {
            firstSubmitAttemptDone, email, name, inProgress, phone, businessName,
            accountUrl, urlRequestInProgress, phoneRequestInProgress, password, formValid,
        } = this.state;

        const passwordErrorVisible = (firstSubmitAttemptDone || password.showError) && !password.valid;

        if (inlineForm) {
            formSubmitText = submitButtonText || l('site.signupForm.buttonStart');
        }

        const signInLink = Cookies.get('account_url') ? 'https://' + Cookies.get('account_url') + '.joinposter.com' : '#';

        const termsLink = `${window.config.langUrl}/terms`,
            privacyLink = getPrivacyLink();

        return (
            <div className={classNames(['step-create-account', { 'one-line-signup': inlineForm }])}>
                <form
                    action=""
                    onSubmit={this.onSubmit}
                    onClick={this.handleFormFocus}
                >
                    { window.config.recaptchaKey && this.state.inlineFormActive && (
                        <ReCAPTCHA
                            ref={this.recaptchaRef}
                            size="invisible"
                            sitekey={window.config.recaptchaKey}
                        />
                    )}

                    { !inlineForm && (
                        <PosterUiKit.BlockTitle
                            title={l('site.signupForm.header')}
                            hint={`${l('site.signupForm.stepXofN', { step: 1, total: 2 })}: ${l('site.signupForm.stepCreatingAccount')}`}
                        />
                    )}

                    <StepAccountInput
                        inlineForm={inlineForm}
                        label={l('site.signupForm.email')}
                        id="input-email"
                        vertical
                        error={(firstSubmitAttemptDone || email.showError) && !email.valid ? this.validationError('email') : ''}
                    >
                        <input
                            type="email"
                            id="input-email"
                            className="input01"
                            value={email.value}
                            onInput={this.onInputChange.bind(this, 'email')}
                            onBlur={this.onInputBlur.bind(this, 'email')}
                            onFocus={this.onInputFocus.bind(this, 'email')}
                            disabled={inProgress}
                            tabIndex={!inlineForm ? 1 : false}
                            ref={this.emailInputRef}
                        />

                        { email.valid && (
                            <div className="valid-checkmark" />
                        )}
                    </StepAccountInput>

                    {isTallyAvailable && <StepAccountInput
                        inlineForm={inlineForm}
                        label={l('site.signupForm.yourName')}
                        id="input-owner-name"
                        vertical
                        error={(firstSubmitAttemptDone || name.showError) && !name.valid ? this.validationError('name') : ''}
                    >
                        <input
                            type="text"
                            id="input-owner-name"
                            className="input01"
                            value={name.value}
                            onInput={this.onInputChange.bind(this, 'name')}
                            onBlur={this.onInputBlur.bind(this, 'name')}
                            onFocus={this.onInputFocus.bind(this, 'name')}
                            disabled={inProgress}
                            tabIndex={!inlineForm ? 1 : false}
                        />

                        {name.valid && (
                            <div className="valid-checkmark" />
                        )}
                    </StepAccountInput>}

                    <StepAccountInput
                        inlineForm={inlineForm}
                        label={l('site.signupForm.phone')}
                        id="input-phone"
                        vertical
                        error={
                            (firstSubmitAttemptDone || phone.showError) &&
                            !phone.valid && !phoneRequestInProgress ? this.phoneValidationError() : ''
                        }
                    >
                        <IntlTelInput
                            containerClassName={classNames(['intl-tel-input', {
                                'input-error': firstSubmitAttemptDone && !phone.valid,
                            }])}
                            preferredCountries={preferredCountries}
                            format
                            separateDialCode
                            autoPlaceholder
                            fieldId="input-phone"
                            nationalMode={false}
                            onPhoneNumberChange={this.onPhoneChange}
                            onPhoneNumberBlur={this.onInputBlur.bind(this, 'phone')}
                            value={phone.value}
                            disabled={inProgress}
                            telInputProps={{ tabIndex: !inlineForm ? 2 : false }}
                            excludeCountries={['ru', 'by']}
                        />

                        { phoneRequestInProgress && (
                            <PosterUiKit.Spinner />
                        )}

                        { !phoneRequestInProgress && phone.valid && (
                            <div className="valid-checkmark" />
                        )}
                    </StepAccountInput>

                    <StepAccountInput
                        inlineForm={inlineForm}
                        label={l('site.signupForm.businessName')}
                        id="input-business-name"
                        className="business-name"
                        vertical
                        error={(firstSubmitAttemptDone || businessName.showError) && !businessName.valid ?
                            this.validationError('businessName') : ''}
                    >
                        <input
                            className="input03"
                            type="text"
                            id="input-business-name"
                            value={businessName.value}
                            onInput={this.onInputChange.bind(this, 'businessName')}
                            onBlur={this.onInputBlur.bind(this, 'businessName')}
                            onFocus={this.onInputFocus.bind(this, 'businessName')}
                            disabled={inProgress}
                            // placeholder={l('site.signupForm.businessNamePlaceholder')}
                            tabIndex={!inlineForm ? 3 : false}
                        />

                        { accountUrl !== '' && (
                            <div className="tip">
                                <PosterUiKit.Tooltip>
                                    {l('site.signupForm.urlHint')}
                                </PosterUiKit.Tooltip>

                                {l('site.signupForm.yourUrl')}: {accountUrl}.joinposter.com
                            </div>
                        )}

                        { urlRequestInProgress && (
                            <PosterUiKit.Spinner />
                        )}

                        { !urlRequestInProgress && businessName.valid && (
                            <div className="valid-checkmark" />
                        )}
                    </StepAccountInput>

                    { !inlineForm && (
                        <PosterUiKit.FormGroup
                            label={l('site.signupForm.password')}
                            id="input-password"
                            vertical
                            error={passwordErrorVisible ? this.validationError('password') : ''}
                        >
                            <input
                                type="password"
                                id="input-password"
                                value={password.value}
                                onInput={this.onInputChange.bind(this, 'password')}
                                onBlur={this.onInputBlur.bind(this, 'password')}
                                onFocus={this.onInputFocus.bind(this, 'password')}
                                disabled={inProgress}
                                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                                tabIndex={4}
                            />

                            { !passwordErrorVisible && !password.valid && (
                                <div className="hint">
                                    {l('site.signupForm.passwordPlaceholder')}
                                </div>
                            )}

                            { password.valid && (
                                <div className="valid-checkmark" />
                            )}
                        </PosterUiKit.FormGroup>
                    )}

                    <PosterUiKit.Button
                        className="ib m-r-15 blue big article-margin-bottom"
                        inputBlocked={
                            (!inlineForm && !formValid) ||
                            (inlineForm && firstSubmitAttemptDone && !formValid) ||
                            urlRequestInProgress ||
                            inProgress}
                        inProgress={inProgress}
                    >
                        {formSubmitText}
                    </PosterUiKit.Button>

                    { window.config.gdprCountry && ((inlineForm && this.state.inlineFormActive) || !inlineForm) && (
                        <div>
                            <p className="article article-margin-bottom">{l('site.signupForm.termsAndConditionsText1')}</p>

                            <div className="article article-margin-bottom">
                                <input
                                    id="acept_privacy_policy"
                                    name="acept_privacy_policy"
                                    type="checkbox"
                                    data-type="privacy_policy"
                                    className="policy_checkbox validate"
                                    data-empty={l('site.signupForm.termsAndConditionsTextError')}
                                    required={true}
                                    onChange={this.handleCheckClick}
                                />
                                <label
                                    className="without-styles label-checkbox"
                                    htmlFor="acept_privacy_policy"
                                    dangerouslySetInnerHTML={{ __html: l('site.signupForm.termsAndConditionsText2') }}
                                >
                                </label>
                            </div>
                        </div>
                    )}

                    { window.config.country === 'PL' && ((inlineForm && this.state.inlineFormActive) || !inlineForm) && (
                        <div>
                            <div className="article article-margin-bottom">
                                <input
                                    id="acept_privacy_policy1"
                                    name="acept_privacy_policy1"
                                    type="checkbox"
                                    data-type="privacy_policy"
                                    className="policy_checkbox validate"
                                    data-empty="Preferencje dotyczące komunikacji są wymagane."
                                    required={true}
                                    onChange={this.handleCheckClick}
                                />
                                <label className="without-styles label-checkbox" htmlFor="acept_privacy_policy1">
                                    E-mail: Wyrażam zgodę na przesyłanie przez Pos
                                    Poland sp. z o.o. wiadomości zawierających informacje handlowe dotyczące produktów i usług Pos
                                    Poland sp. z o.o.,w formie wiadomości e-mail na telekomunikacyjne urządzenia końcowe, z których
                                    korzystam.
                                </label>
                            </div>

                            <div className="article article-margin-bottom">
                                <input
                                    id="acept_privacy_policy2"
                                    name="acept_privacy_policy2"
                                    type="checkbox"
                                    data-type="privacy_policy"
                                    className="policy_checkbox validate"
                                    data-empty="Preferencje dotyczące komunikacji są wymagane."
                                    required={true}
                                    onChange={this.handleCheckClick}
                                />
                                <label className="without-styles label-checkbox" htmlFor="acept_privacy_policy2">
                                    Rozmowa telefoniczna / sms / komunikator: Wyrażam
                                    zgodę na kontakt i rozmowę telefoniczną (głosową) / otrzymywanie wiadomości sms / otrzymywanie
                                    komunikatów przez Pos Poland sp. z o.o. na telekomunikacyjne urządzenia końcowe z których korzystam,
                                    w celu informowania o produktach i usługach Pos Poland sp. z o.o.
                                </label>
                            </div>
                        </div>
                    )}

                    { !inlineForm && (
                        <div>
                            <p
                                className="legal"
                                dangerouslySetInnerHTML={{ __html: getTermsAndPrivacyText(formSubmitText) }}
                            />
                            <p
                                className="already-registered"
                                dangerouslySetInnerHTML={{ __html: l('site.signupForm.step1AlreadyRegistered', signInLink) }}
                            />
                        </div>
                    )}

                    { inlineForm && this.state.inlineFormActive && (
                        <p
                            className="legal"
                            dangerouslySetInnerHTML={{ __html: getTermsAndPrivacyText(formSubmitText) }}
                        />
                    )}
                </form>
            </div>
        );
    }
}
